import { Injectable } from '@angular/core';
import { Farm } from '../model/farm';
import { Account } from '../model/account';
import { HttpService } from './http.service';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { Offer, OfferItem } from '../model/offer';
import { Router } from '@angular/router';
import { CartService } from './cart.service';
import { EventManager } from '@angular/platform-browser';
import { CONSTANTS } from '../model/enums';
import { CartEntry } from '../model/cart-entry';
import { ILoginResponse } from '../model/responses/login-response';
import * as moment from 'moment';
import { ILoginRequest } from '../model/requests/login-request';

interface ICartCache {
    entries: CartEntry[];
    pickupLocationId: number;
}

@Injectable({
    providedIn: 'root'
})
export class SessionService {

    private isLoggedIn = false;
    private offer: Offer;
    private farm: Farm;
    private account: Account;
    private currencyCode = 'USD';

    private farmSubject: BehaviorSubject<Farm>; // Stores the last farm value and replays it on subscription
    private offerSubject: BehaviorSubject<Offer>; // Stores the last offer value and replays it on subscription
    private logoutSubject: Subject<void>;
    private loginSubject: Subject<void>;

    constructor(private http: HttpService, private router: Router, private cart: CartService, private eventManager: EventManager) {
        this.farmSubject = new BehaviorSubject<Farm>(null);
        this.offerSubject = new BehaviorSubject<Offer>(null);
        this.logoutSubject = new Subject<void>();
        this.loginSubject = new Subject<void>();

        this.eventManager.addGlobalEventListener('window', 'unload', () => {
            if (this.getIsLoggedIn() && !this.offer.isClosed()) {
                window.localStorage.setItem(this.getCacheKey(CONSTANTS.STORAGE_KEYS.ACCOUNT), JSON.stringify(this.account));
                window.localStorage.setItem(this.getCacheKey(CONSTANTS.STORAGE_KEYS.FARM), JSON.stringify(this.farm));
                Object.values(this.offer.items).forEach((item) => {
                    item.sold = item.serverSold; // Reset this value when leaving the page
                });
                window.localStorage.setItem(this.getCacheKey(CONSTANTS.STORAGE_KEYS.OFFER), JSON.stringify(this.offer));
                this.saveCartToCache();

                // Only update the expiration date if it's not set; otherwise you'll keep delaying by 30 minutes
                if (!window.localStorage.getItem(this.getCacheKey(CONSTANTS.STORAGE_KEYS.EXPIRATION))) {
                    const thirtyFromNow = moment(new Date()).add(30, 'minutes');
                    const expirationDate: Date = thirtyFromNow.toDate() < this.offer.window.close ?
                        thirtyFromNow.toDate() :
                        this.offer.window.close;
                    window.localStorage.setItem(this.getCacheKey(CONSTANTS.STORAGE_KEYS.EXPIRATION), expirationDate.toString());
                }
            }
        });
    }

    getCacheKey(key: string, farmSlug?: string, offerId?: number) {
        const farmKeySegment: string = farmSlug || this.farm.slug;
        let offerKeySegment: number = offerId;
        if (!offerKeySegment) {
            if(!this.offer) {
                return null;
            }
            offerKeySegment = this.offer.id;
        }
        const cacheKey = `${farmKeySegment}_${offerKeySegment}_${key}`;
        return cacheKey;
    }

    getCartCacheKey(buyerUserId: number, farmSlug?: string, offerId?: number) {
        return `${this.getCacheKey(CONSTANTS.STORAGE_KEYS.CART, farmSlug, offerId)}_${buyerUserId}`;
    }

    loadFromCache(farmSlug: string, offerId: number): boolean {
        const expirationString = window.localStorage.getItem(this.getCacheKey(CONSTANTS.STORAGE_KEYS.EXPIRATION, farmSlug, offerId));
        if (expirationString) {
            const expiration = new Date(expirationString);
            const now = new Date();
            if (now < expiration) {
                this.account = new Account(JSON.parse(window.localStorage.getItem(
                    this.getCacheKey(CONSTANTS.STORAGE_KEYS.ACCOUNT, farmSlug, offerId)
                )));
                this.isLoggedIn = true;
                const farm = new Farm(JSON.parse(window.localStorage.getItem(
                    this.getCacheKey(CONSTANTS.STORAGE_KEYS.FARM, farmSlug, offerId)
                )));
                this.setFarm(farm, farm.slug);
                const offer = new Offer(JSON.parse(window.localStorage.getItem(
                    this.getCacheKey(CONSTANTS.STORAGE_KEYS.OFFER, farmSlug, offerId)
                )));
                this.setOffer(offer);
                this.loadCartFromCache();
                this.killCartCache(this.account.buyerUserId, farmSlug, offerId); // Otherwise the old cart keeps getting reloaded
                return true;
            } else {
                this.killCache(farmSlug, offerId);
            }
        }

        return false;
    }

    getCurrencyCode(): string {
        return this.currencyCode;
    }

    getIsLoggedIn(): boolean {
        return this.isLoggedIn;
    }

    hasOffer(): boolean {
        return !!this.offer;
    }

    getOffer(): BehaviorSubject<Offer> {
        return this.offerSubject;
    }

    setOffer(offer: Offer) {
        this.offer = offer;
        this.offerSubject.next(this.offer);
    }

    getAccount(): Account {
        return this.account;
    }

    hasFarm(): boolean {
        return !!this.farm;
    }

    getFarm(): BehaviorSubject<Farm> {
        return this.farmSubject;
    }

    setFarm(farm: Farm, slug: string) {
        // check type farm is object with the case response fail is string => undefine
        if (typeof farm === 'object' && farm !== null) {
            this.farm = farm;
            this.farm.slug = slug;
            this.currencyCode = this.farm.currencyCode;
            this.farmSubject.next(this.farm);
        }
    }

    login(email: string, password: string): Observable<ILoginResponse> {
        return new Observable<ILoginResponse>((observer) => {
            this.http.post<ILoginRequest, ILoginResponse>('farmlisting/login', {
                email,
                password: btoa(password),
                offerId: this.offer.id
            }).subscribe((response: ILoginResponse) => {
                if (response.success) {
                    this.account = new Account(response.account);
                    this.isLoggedIn = true;
                    this.loginSubject.next();
                    this.loadCartFromCache();
                }
                observer.next(response);
                observer.complete();
            }, (error) => {
                console.error(error);
                observer.error(error);
            });
        });
    }

    private saveCartToCache() {
        const cartCache: ICartCache = {
            entries: this.cart.getEntries(),
            pickupLocationId: this.cart.getPickupLocationId()
        };
        window.localStorage.setItem(this.getCartCacheKey(this.account.buyerUserId), JSON.stringify(cartCache));
    }

    private loadCartFromCache(farmSlug?: string, offerId?: number): void {
        if (!this.getIsLoggedIn()) {
            return;
        }
        farmSlug = farmSlug || this.farm.slug;
        offerId = offerId || this.offer.id;
        const cartCache: ICartCache = JSON.parse(window.localStorage.getItem(
            this.getCartCacheKey(this.account.buyerUserId, farmSlug, offerId)
        ));
        if (cartCache) {
            const cartEntries = cartCache.entries;
            if (cartEntries) {
                cartEntries.forEach((entry) => {
                    const item = this.offer.items[entry.item.id];
                    if (item) {
                        item.sold = item.serverSold; // Reset this value, since it will get updated by adding below
                        this.cart.addItem(item, entry.quantity);
                    }
                });
            }
            this.cart.setPickupLocationId(cartCache.pickupLocationId);
        }
    }

    public saveOfferViewStyleToCache(isListView: boolean) {
        if (this.account) {
            window.localStorage.setItem(`${this.account.buyerUserId}_${CONSTANTS.STORAGE_KEYS.OFFER_VIEW_STYLE}`,
                JSON.stringify(isListView));
        }
    }

    public getOfferViewStyleFromCache(): boolean {
        if (this.account) {
            return JSON.parse(window.localStorage.getItem(`${this.account.buyerUserId}_${CONSTANTS.STORAGE_KEYS.OFFER_VIEW_STYLE}`));
        } else {
            return false;
        }
    }

    public killCache(farmSlug?: string, offerId?: number): void {
        this.killNonCartCache(farmSlug, offerId);
        this.killCartCache();
    }

    public killNonCartCache(farmSlug?: string, offerId?: number): void {
        window.localStorage.removeItem(this.getCacheKey(CONSTANTS.STORAGE_KEYS.ACCOUNT, farmSlug, offerId));
        window.localStorage.removeItem(this.getCacheKey(CONSTANTS.STORAGE_KEYS.FARM, farmSlug, offerId));
        window.localStorage.removeItem(this.getCacheKey(CONSTANTS.STORAGE_KEYS.OFFER, farmSlug, offerId));
        window.localStorage.removeItem(this.getCacheKey(CONSTANTS.STORAGE_KEYS.EXPIRATION, farmSlug, offerId));
    }

    public killCartCache(buyerUserId?: number, farmSlug?: string, offerId?: number): void {
        if (!(buyerUserId || this.account)) {
            return;
        }
        window.localStorage.removeItem(this.getCartCacheKey(buyerUserId || this.account.buyerUserId, farmSlug, offerId));
    }

    logout(): void {
        this.saveCartToCache();
        this.cart.clear();
        this.killNonCartCache();
        this.isLoggedIn = false;
        delete this.account;
        this.logoutSubject.next();
    }

    getLogoutSubject(): Subject<void> {
        return this.logoutSubject;
    }

    getLoginSubject(): Subject<void> {
        return this.loginSubject;
    }
}
