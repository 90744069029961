import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'assetImage'
})
export class AssetImagePipe implements PipeTransform {

    transform(value: any, ...args: any[]): any {
        return `assets/images/${value}`;
    }

}
