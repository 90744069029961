import * as moment from 'moment';

export interface IOffer {
    id: number;
    window: IOfferWindow;
    message: string;
    items: { [id: number]: IOfferItem };
}

export interface IOfferWindow {
    open: number;
    close: number;
    delivery: number;
}

export interface IOfferItem {
    id: number;
    itemDetailId: number;
    itemCategoryId: number;
    name: string;
    imageUrl: string;
    unit: IOfferUnit;
    sold: number;
    limit: number;
    price: number;
    quantity?: number;
    available: number;
}

export interface IOfferUnit {
    name: string;
    abbreviation: string;
}

export class Offer {
    id: number;
    status: string;
    window: OfferWindow;
    message: string;
    items: { [id: number]: OfferItem };

    readonly MINUTES_IN_A_WEEK = 10080;
    readonly MINUTES_IN_A_DAY = 1440;
    readonly MINUTES_IN_AN_HOUR = 60;

    constructor(data: IOffer) {
        this.id = data.id;
        this.window = new OfferWindow(data.window);
        this.message = data.message;
        this.items = {};
        for (const item of Object.values(data.items)) {
            if (item.price && item.price > 0) {
                this.items[item.id] = new OfferItem(item);
            }
        }
    }

    getStatus(): string {
        const now = moment();
        let duration;
        let prefix = '';
        let suffix = '';
        if (this.isClosed()) {
            duration = moment.duration(moment(this.window.open).diff(now));
            prefix = 'Opening in ';
        } else {
            duration = moment.duration(moment(this.window.close).diff(now));
            suffix = ' remaining';
        }
        if (duration.as('days') >= 2) {
            return `${prefix}${Math.floor(duration.as('days'))} days${suffix}`;
        } else if (duration.as('days') >= 1) {
            return `${prefix}1 day${suffix}`;
        } else if (duration.as('hours') >= 2) {
            return `${prefix}${Math.floor(duration.as('hours'))} hours${suffix}`;
        } else if (duration.as('hours') >= 1) {
            return `${prefix}1 hour${suffix}`;
        } else if (duration.as('minutes') >= 5) {
            return `${prefix}<1 hour${suffix}`;
        } else if (duration.as('seconds') >= 5) {
            return `${prefix}<5 minutes${suffix}`;
        } else {
            return this.isClosed() ? 'Opening...' : 'Closing...';
        }
    }

    isClosed(): boolean {
        const now = new Date();
        if (now < this.window.open || now >= this.window.close) {
            return true;
        } else {
            return false;
        }
    }
}

export class OfferWindow {
    open: Date;
    close: Date;
    delivery: Date;

    constructor(data: IOfferWindow) {
        this.open = new Date(data.open);
        this.close = new Date(data.close);
        this.delivery = new Date(data.delivery);
    }
}

export class OfferItem implements IOfferItem {
    id: number;
    itemDetailId: number;
    itemCategoryId: number;
    name: string;
    imageUrl: string;
    unit: OfferUnit;
    sold: number; // Server sold + client sold (cart value)
    serverSold: number;
    limit: number;
    price: number;
    available: number;

    constructor(data: IOfferItem) {
        this.id = data.id;
        this.itemDetailId = data.itemDetailId;
        this.itemCategoryId = data.itemCategoryId;
        this.name = data.name;
        this.imageUrl = data.imageUrl;
        this.unit = new OfferUnit(data.unit);
        this.sold = +data.sold || 0;
        this.serverSold = data.sold;
        this.limit = data.limit || 999;
        this.price = data.price;
        this.available = data.available || 999;
    }

    public getAvailable() {
        if (!this.isLimitSet()) {
            return undefined;
        } else {
            return Math.max(0, this.limit - this.sold);
        }
    }

    public getAvailableLabel() {
        if (this.isLimitSet() === 999) { // Limit was not set
            return 'Open';
        } else if (this.isSoldOut()) {
            return 'Sold out';
        } else {
            return `${this.getAvailable()} available`;
        }
    }

    public getServerRemainingLabel() {
        const serverRemaining = Math.max(0, this.limit - this.serverSold);
        if (serverRemaining > 0) {
            return `There are ${ serverRemaining } left`;
        } else {
            return 'Sold out';
        }
    }

    public isSoldOut() {
        return this.isLimitSet() && this.getAvailable() <= 0;
    }

    public isLimitSet() {
        return this.limit || this.limit === 999;
    }
}

export class OfferUnit {
    name: string;
    abbreviation: string;

    constructor(data: IOfferUnit) {
        this.name = data.name;
        this.abbreviation = data.abbreviation;
    }
}
