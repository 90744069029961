import { Component, OnInit, ElementRef } from '@angular/core';
import { IModalDialog } from '../../generic/modal/modal.component';
import { MatDialogRef } from '@angular/material/dialog';
import { HttpService } from 'src/app/services/http.service';
import { IChangePasswordRequest } from 'src/app/model/requests/change-password-request';
import { SessionService } from 'src/app/services/session.service';
import { ToastService } from 'src/app/services/toast.service';
import { EnumChangePasswordStatus, IChangePasswordResponse } from 'src/app/model/responses/change-password-response';
import { Account } from 'src/app/model/account';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.less']
})
export class ChangePasswordComponent implements IModalDialog, OnInit {

    labels = {
        lblChangePassword: 'Change password',
        lblCurrentPassword: 'Current password',
        lblNewPassword: 'New password',
        lblConfirmNewPassword: 'Confirm new password',
        lblPasswordChanged: 'Your password has been updated.',
        lblDismiss: 'Dismiss',
        lblCredentialsFailed: 'This does not match your current password',
        lblFailed: 'Something went wrong. Unable to update your password.',
        placeholderNewPassword: 'Password must be at least 8 characters',
        btnCancel: 'Cancel',
        btnSave: 'Save'
    };

    farmSlug: string;

    currentPassword: string;
    newPassword: string;
    confirmNewPassword: string;

    haveCredentialsFailed = false;

    saveButton: HTMLElement;

    constructor(public dialogRef: MatDialogRef<ChangePasswordComponent>, private http: HttpService,
                private session: SessionService, private toast: ToastService, private element: ElementRef) { }

    ngOnInit() {
        this.saveButton = this.element.nativeElement.querySelector('.btnSave');
        this.session.getFarm().subscribe((farm) => {
            if (farm) {
                this.farmSlug = farm.slug;
            }
        });
    }

    canClose(): boolean {
        return true;
    }

    isMinor(): boolean {
        return true;
    }

    close() {
        this.dialogRef.close();
    }

    enterPressed() {
        this.saveButton.click();
    }

    changePassword() {
        this.http.post<IChangePasswordRequest, IChangePasswordResponse>('farmlisting/settings/changePassword', {
            farmSlug: this.farmSlug,
            currentPassword: btoa(this.currentPassword),
            newPassword: btoa(this.newPassword)
        }).subscribe((response: IChangePasswordResponse) => {
            const loggedInAccount: Account = this.session.getAccount();
            loggedInAccount.loginToken = response.loginToken;
            this.toast.open({
                text: this.labels.lblPasswordChanged,
                actions: [
                    {
                        label: this.labels.lblDismiss,
                        callback: () => { }
                    }
                ]
            });
            this.close();
        }, (response: IChangePasswordResponse) => {
            if (response.status === EnumChangePasswordStatus.BAD_CREDENTIALS) {
                this.haveCredentialsFailed = true;
            } else {
                this.toast.open({
                    text: this.labels.lblFailed,
                    isError: true,
                    actions: [{
                        label: this.labels.lblDismiss,
                        callback: () => {}
                    }]
                });
            }
        });
    }

}
