import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-no-farm',
    templateUrl: './no-farm.component.html',
    styleUrls: ['./no-farm.component.less']
})
export class NoFarmComponent implements OnInit {

    icon = 'farm.svg';
    heading = 'Can\'t find that farm';
    message = 'Whoops, looks like the farm you are looking for doesn\'t exist. Check the spelling or URL of the farm.';

    constructor() { }

    ngOnInit() {
    }

}
