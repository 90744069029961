export interface IFarm {
    id: number;
    name: string;
    slug: string;
    email: string;
    phone: string;
    website: string;
    logoUrl: string;
    currencyCode: string;
    timeZoneId: string;
    about: string;
    storyImageUrls: string[];
    facebook: string;
    instagram: string;
    youtube: string;
    twitter: string;
    pickupLocations: IPickupLocation[];
}

export interface IPickupLocation {
    id: number;
    name: string;
    price: number;
}

export class Farm implements IFarm {
    id: number;
    name: string;
    slug: string;
    email: string;
    phone: string;
    website: string;
    logoUrl: string;
    currencyCode: string;
    timeZoneId: string;
    about: string;
    storyImageUrls: string[];
    facebook: string;
    instagram: string;
    youtube: string;
    twitter: string;
    pickupLocations: PickupLocation[];

    constructor(farm: IFarm) {
        Object.assign(this, farm);
    }
}

export class PickupLocation implements IPickupLocation {
    id: number;
    name: string;
    price: number;

    constructor(location: IPickupLocation) {
        this.id = location.id;
        this.name = location.name;
        this.price = location.price;
    }

}
