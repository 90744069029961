import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ToastComponent } from '../components/generic/toast/toast.component';
import { Router } from '@angular/router';
import { CONSTANTS } from '../model/enums';
import { SessionService } from './session.service';

export class ToastNamedAction {
    label: string;
    callback: () => void;
}

export class ToastConfig {
    text: string;
    actions?: ToastNamedAction[];
    isError ? = false;
    duration ? = 3000;
    closeX ? = false;
}

@Injectable({
    providedIn: 'root'
})
export class ToastService {

    farmSlug: string;

    constructor(private snackBar: MatSnackBar, private router: Router, private session: SessionService) {
        this.session.getFarm().subscribe((farm) => {
            if (farm) {
                this.farmSlug = farm.slug;
            }
        });
    }

    open(customConfig: ToastConfig) {
        const panelClass = ['fl-toast'];
        if (customConfig.isError) {
            panelClass.push('fl-toast-warn');
        }
        customConfig.actions.forEach((action, index, actions) => {
            actions[index] = {
                label: action.label,
                callback: () => {
                    action.callback();
                    snackBarRef.dismiss();
                }
            };
        });
        const config: MatSnackBarConfig = {
            verticalPosition: 'top',
            panelClass,
            duration: customConfig.duration || 3000,
            data: {
                text:customConfig.text,
                actions:customConfig.actions,
                closeX:customConfig.closeX
            }
        };

        const snackBarRef = this.snackBar.openFromComponent(ToastComponent, config);
        // snackBarRef.afterOpened().subscribe(() => {
        //     snackBarRef.instance.text = customConfig.text;
        //     if (customConfig.closeX) {
        //       snackBarRef.instance.closeX = true;
        //     }
            
        //     snackBarRef.instance.actions = customConfig.actions;
        // });

        return snackBarRef;
    }

    openItemAddedToast(item, quantity) {
        this.open({
            text: `(${quantity}) ${item.name} added to your cart`,
            actions: [
                {
                    label: 'View cart',
                    callback: () => {
                        this.router.navigate([CONSTANTS.ROUTES.CART(this.farmSlug)]);
                    }
                }
            ]
        });
    }
}
