import { Component, Directive, Input, ElementRef, Output, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

export interface IModalDialog {
    canClose(): boolean;
    isMinor(): boolean;
}

// tslint:disable-next-line: directive-selector
@Directive({selector: 'app-modal-header'})
export class ModalHeaderDirective {
    constructor(el: ElementRef) {
        el.nativeElement.classList.add('fl-h2', 'fl-modal-header', 'flex-column-center');
    }
}

// tslint:disable-next-line: directive-selector
@Directive({selector: 'app-modal-content'})
export class ModalContentDirective {
    @Output() el: HTMLElement;
    constructor(el: ElementRef) {
        this.el = el.nativeElement;
        el.nativeElement.classList.add('fl-modal-content', 'flex-column-center');
    }
}

// tslint:disable-next-line: directive-selector
@Directive({selector: 'app-modal-footer'})
export class ModalFooterDirective {
    constructor(el: ElementRef) {
        el.nativeElement.classList.add('fl-modal-footer', 'flex-row-center', 'flex-justify-end');
    }
}

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.less']
})
export class ModalComponent implements OnInit {

    @Input() dialogRef: MatDialogRef<IModalDialog>;

    constructor(private el: ElementRef) { }

    ngOnInit() {
        if (!this.dialogRef.componentInstance.canClose()) {
            const modal: HTMLElement = this.el.nativeElement.querySelector('.fl-modal');
            modal.classList.add('fl-modal-no-close');
        }

        if (this.dialogRef.componentInstance.isMinor()) {
            const modal: HTMLElement = this.el.nativeElement.querySelector('.fl-modal');
            modal.classList.add('fl-modal-minor');
        }
    }

    close() {
        if (this.dialogRef.componentInstance.canClose()) {
            this.dialogRef.close();
        }
    }

}
