import { Injectable, OnInit } from '@angular/core';
import { CartEntry } from '../model/cart-entry';
import { OfferItem } from '../model/offer';

@Injectable({
    providedIn: 'root'
})
export class CartService {

    private entries: { [key: number]: CartEntry } = {};
    private pickupLocationId: number;

    addItem(item: OfferItem, quantity) {
        item.sold = item.sold + quantity;
        if (!this.entries[item.id]) {
            this.entries[item.id] = new CartEntry(item, quantity);
        } else {
            this.entries[item.id].add(quantity);
        }
    }

    updateItem(id: number, quantity) {
        const entry = this.entries[id];
        const item = entry.item;
        const difference = (quantity || 0) - (entry.quantity || 0);
        item.sold = item.sold + difference;
        entry.quantity = (quantity || 0);
    }

    removeItem(id: number) {
        const entry = this.entries[id];
        const item = entry.item;
        item.sold = item.sold - entry.quantity;
        delete this.entries[id];
    }

    setPickupLocationId(id: number) {
        this.pickupLocationId = id;
    }

    getPickupLocationId(): number {
        return this.pickupLocationId;
    }

    getEntries(): CartEntry[] {
        return Object.values(this.entries);
    }

    getItemCount(): number {
        return this.getEntries().length;
    }

    getSubtotal(entries?: CartEntry[]): number {
        entries = entries || this.getEntries();
        const total = entries.reduce((tempTotal, cartEntry, index, items) => {
            return tempTotal + (cartEntry.quantity * cartEntry.item.price);
        }, 0);
        return total;
    }

    removeEmpties() {
        const keysToRemove = [];
        for (const [id, entry] of Object.entries(this.entries)) {
            if (entry.quantity <= 0 || !entry.quantity) {
                keysToRemove.push(id);
            }
        }

        keysToRemove.forEach((key) => {
            delete this.entries[key];
        });
    }

    hasEntries() {
        return this.getEntries().length > 0;
    }

    clear() {
        this.entries = {};
    }
}
