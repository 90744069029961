import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SessionService } from '../services/session.service';
import { Account } from '../model/account';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private session: SessionService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler) {
        const loggedInUser: Account = this.session.getAccount();
        if (loggedInUser) {
            const loginToken = loggedInUser.loginToken;
            const authorizedRequest = request.clone({
                setHeaders: { Authorization: 'Basic ' + loginToken }
            });
            return next.handle(authorizedRequest);
        }

        // Not logged in, so just forward the original request along
        return next.handle(request);
    }
}
