import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionService } from '../services/session.service';
import { CONSTANTS } from '../model/enums';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(private session: SessionService, private router: Router, private route: ActivatedRoute) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (this.session.getIsLoggedIn()) {
            return true;
        } else {
            const farmSlug = next.paramMap.get(CONSTANTS.ROUTE_PARAM_KEYS.FARM_SLUG);
            if (farmSlug) {
                this.router.navigate([CONSTANTS.ROUTES.OFFER(farmSlug, -1)]);
                return true;
            } else {
                return false;
            }
        }
    }
}
