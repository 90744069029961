import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { map } from 'rxjs/operators';
import { CONSTANTS } from '../model/enums';

@Injectable({
    providedIn: 'root'
})
export class PaymentService {

    constructor(private http: HttpService,
    ) { }

    createPayment(cardKey:string,farmid:any,data : any,orderid:string,order:any) {
        var obj = data;
        obj.farmid = farmid;
        obj.sourceId = cardKey;
        obj.orderid = orderid;
        obj.order = order;
        // obj.sourceId = "cnon:card-nonce-ok";
        // var tt = parseFloat(total) * 100;
        // obj.amountMoney = { "amount": tt, "currency": "USD" };

        return this.http.post<any,any>(CONSTANTS.API_ENDPOINT.CREATE_PAYMENT, obj).pipe(
            map((body: any) => {
                return body;
            })
        );
    }

    createOrder(data) {
        return this.http.post<any,any>(CONSTANTS.API_ENDPOINT.CREATE_ORDER, data).pipe(
            map((body: any) => {
                return body;
            })
        );
    }

    checkSoldOut(data) {
        return this.http.post<any,any>(CONSTANTS.API_ENDPOINT.CHECK_SOLD_OUT, data).pipe(
            map((body: any) => {
                return body;
            })
        );
    }

    getCountry() {
        return this.http.get<any>(CONSTANTS.API_ENDPOINT.GET_COUNTRY).pipe(
            map((body: any) => {
                return body;
            })
        );
    }

    getState() {
        return this.http.get<any>(CONSTANTS.API_ENDPOINT.GET_STATE).pipe(
            map((body: any) => {
                return body;
            })
        );
    }

    sendOrderMetaData(data) {
        return this.http.post<any, any>(CONSTANTS.API_ENDPOINT.SEND_ORDER_META_DATA, data).pipe(
            map((body: any) => {
                return body;
            })
        );
    }
}
