import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CartService } from 'src/app/services/cart.service';
import { SessionService } from 'src/app/services/session.service';
import { CONSTANTS } from 'src/app/model/enums';
import { LayoutService } from 'src/app/services/layout.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastService } from 'src/app/services/toast.service';
import { AddItemComponent } from '../../offer/add-item/add-item.component';
import { HttpService } from 'src/app/services/http.service';
import { Offer, OfferItem } from 'src/app/model/offer';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { IOfferResponse, IOfferFullResponse } from 'src/app/model/responses/offer-response';
import { StoreService } from 'src/app/services/store.service';
import { DataStorageService } from 'src/app/services/storage.service';
import * as moment from 'moment';
import { GTMService } from 'src/app/services/GTM.service';

@Component({
  selector: 'app-store',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.less']
})
export class StoreComponent implements OnInit, OnDestroy {

  labels = {
    btnAdd: 'Add',
    lblQuantity: 'Enter amount',
    lblLogout: 'Log out',
    lblView: 'View',
    lblNotStarted: 'This offer has not started',
    placeholderEnterAmount: 'Enter amount',
    btnDismiss: 'Dismiss',
  };
  CONSTANTS = CONSTANTS;

  farmSlug: string;
  farmInfo:any;
  offersheetId: number;
  offer: any = [];
  deliveryHeader: string;
  nextOrderWindow: string;
  statusHeader: string;
  statusClass: string;
  timeRemaining: string;
  message: string;
  items: any = [];
  isClosed = true;
  entries: any = [];
  arrIdChecked: any = [];

  public statusIntervalId: number;
  private isListView = false;

  public noOffer = false;
  public noFarm = false;
  public isFinishedLoadingOfferData = false;
  constructor(public cart: CartService, public session: SessionService, private dialog: MatDialog,
    public layout: LayoutService, private toast: ToastService, private storeService: StoreService, private router: Router,
    private route: ActivatedRoute, private http: HttpService, private storage: DataStorageService,private gtm:GTMService) {
  }

  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      this.farmSlug = params.get(CONSTANTS.ROUTE_PARAM_KEYS.FARM_SLUG);
      this.storage.resetFarmStorage(this.farmSlug);
      this.entries = this.storage.getCartList(this.farmSlug) || [];
      this.loadOffer(this.farmSlug);
      // this.setIsListView(true);
    });
  }

  ngOnDestroy() {
    if (this.statusIntervalId) {
      window.clearInterval(this.statusIntervalId);
    }

  }

  loadOffer(farmSlug: string) {
    const params = { ...{farmSlug: farmSlug}};
    Object.keys(params).forEach((key) => (params[key] === null || params[key] === '') && delete params[key]);

    this.storeService.getPublicOffer(params).subscribe((response: IOfferFullResponse) => {
      // check case if not offer
      if (!response.data || !response.data.offer || response.data.offer == null) {
        if (response.meta.code === '47_16_f') {
          // return to no offer page
          this.noOffer = true;
          // this.router.navigate([CONSTANTS.ROUTES.NO_OFFER],
          //   {queryParams: {farmSlug}});
        } else if (response.meta.code === '48_1_f') {
          // return to no farm page
          this.storage.resetFarmStorage(this.farmSlug);
          this.noFarm = true;
          // this.router.navigate([CONSTANTS.ROUTES.NO_FARM]);
        }
        this.entries.length = 0;
        this.storage.resetCartStorage(this.farmSlug);
        return;
      }

      if (!response.data.offer.window) {
        response.data.offer.window = {
          open: null,
          close: null,
          delivery: null
        }
      }

      // check case not any item => show offer page
      if (!response.data.offer.items || response.data.offer.items == null) {
        this.noOffer = true;
        this.entries.length = 0;
        this.storage.resetCartStorage(this.farmSlug);
        // this.router.navigate([CONSTANTS.ROUTES.NO_OFFER],
        //   {queryParams: {farmSlug}});
        return;
      }


      this.offer = response.data.offer;
      this.session.setFarm(response.data.farm, farmSlug);
      this.session.setOffer(this.offer);
      this.storage.setFarm(response.data.farm, farmSlug);
      this.farmInfo = response.data.farm;

      this.calculateDisplay(farmSlug);
    }, (error: HttpErrorResponse) => {

      if (error.status === 404) {
        if (error.error.meta.code === '47_16_f') {
          this.session.setFarm(error.error.data.farm, farmSlug);
          this.noOffer = true;
          // this.router.navigate([CONSTANTS.ROUTES.NO_OFFER],
          //   {queryParams: {farmSlug}});
        } else {
          this.storage.resetFarmStorage(this.farmSlug);
          this.noFarm = true;
          // this.router.navigate([CONSTANTS.ROUTES.NO_FARM]);
        }
        this.entries.length = 0;
        this.storage.resetCartStorage(this.farmSlug);
      } else {
        console.error(error);
        this.storage.resetFarmStorage(this.farmSlug);
        this.noFarm = true;
        // this.router.navigate([CONSTANTS.ROUTES.NO_FARM]);
      }
    }, () => {
      this.isFinishedLoadingOfferData = true;
    });
  }

  calculateDisplay(farmSlug) {
    this.deliveryHeader = `Delivery date ${new Date(this.offer.window.delivery).toLocaleDateString([], CONSTANTS.DATE_FORMATS.abbreviated)}`;
    this.showNextOrderWindow();
    this.updateOfferStatus();
    if (this.statusIntervalId) {
      window.clearInterval(this.statusIntervalId);
    }
    this.statusIntervalId = window.setInterval(() => {
      this.updateOfferStatus();
    }, 5000);
    this.message = this.offer.message ? this.offer.message.replace(/[\n\r\t]/g, '<br/>') : undefined;
    this.items = Object.values(this.offer.items).sort((a: OfferItem, b: OfferItem) => {
      // Sort by name then id ascending
      const nameSort = a.name.localeCompare(b.name);
      if (nameSort === 0) {
        return a.itemDetailId - b.itemDetailId;
      } else {
        return nameSort;
      }
    });

    this.items.forEach((item, index) => {
      item.sold = +item.sold || 0;
      item.limit = item.limit || 999;
      item.available = +item.available || 999;
      item.soldOut = ((item.sold >= item.limit) || (item.available <= 0)) ? true : false;
    });


    this.isClosed = this.isClose();
    this.isListView = this.session.getOfferViewStyleFromCache();
    if(this.isMobile()){
      this.isListView = true;
    }
    if(this.isClose()) {
      this.noOffer = true;
      this.entries.length = 0;
      this.storage.resetCartStorage(this.farmSlug);
      // this.router.navigate([CONSTANTS.ROUTES.NO_OFFER],
      //   {queryParams: {farmSlug}});
    }
  }

  isMobile(){
    return /Mobi|Android/i.test(navigator.userAgent);
  }

  showNextOrderWindow() {

    const unixTimestamp = this.offer.window.open;
    const dateObject = new Date(unixTimestamp);
    const options = { hour: "2-digit", minute: "2-digit", hour12: true};

    const date = dateObject.toLocaleString("en-US", {weekday: "long"})
    const month = dateObject.toLocaleString("en-US", {month: "long"})
    const day = dateObject.toLocaleString("en-US", {day: "numeric"})
    const hour = dateObject.toLocaleString("en-US", {hour: "numeric"})
    const minute = dateObject.toLocaleString("en-US", {minute: "numeric"})
    var time = dateObject.toLocaleTimeString([], options);

    this.nextOrderWindow = `${date}, ${month} ${day} ${time}`;
  }

  isClose() {
    const now = (new Date()).getTime();
    if (now < this.offer.window.open || now >= this.offer.window.close) {
      return true;
    } else {
      return false;
    }
  }

  getStatus(): string {
    const now = moment();
    let duration;
    let prefix = '';
    let suffix = '';
    if (this.isClose()) {
      duration = moment.duration(moment(this.offer.window.open).diff(now));
      prefix = 'Opening in ';
    } else {
      duration = moment.duration(moment(this.offer.window.close).diff(now));
      suffix = ' remaining';
    }
    if (duration.as('days') >= 2) {
      return `${prefix}${Math.floor(duration.as('days'))} days${suffix}`;
    } else if (duration.as('days') >= 1) {
      return `${prefix}1 day${suffix}`;
    } else if (duration.as('hours') >= 2) {
      return `${prefix}${Math.floor(duration.as('hours'))} hours${suffix}`;
    } else if (duration.as('hours') >= 1) {
      return `${prefix}1 hour${suffix}`;
    } else if (duration.as('minutes') >= 5) {
      return `${prefix}<1 hour${suffix}`;
    } else if (duration.as('seconds') >= 5) {
      return `${prefix}<5 minutes${suffix}`;
    } else {
      return this.isClose() ? 'Opening...' : 'Closing...';
    }
  }

  updateOfferStatus() {
    const isClosed = this.isClose();
    if (isClosed) {
      this.statusHeader = CONSTANTS.OFFER_STATUS.CLOSED;
      this.statusClass = 'closed';
      this.timeRemaining = this.getStatus();

    } else {
      this.statusHeader = CONSTANTS.OFFER_STATUS.OPEN;
      this.statusClass = 'open';
      this.timeRemaining = this.getStatus();
    }
  }

  getCartLink() {
    return `/${CONSTANTS.ROUTES.CART_PUBLIC(this.farmSlug)}`;
  }

  getCartStatus() {
    return `Cart (${this.entries.length})`;
  }

  getSubtotal() {
    const total = this.entries.reduce((tempTotal, cartEntry, index, items) => {
      return tempTotal + (cartEntry.quantity * cartEntry.item.price);
    }, 0);
    return total;
  }

  addItem(item, quantity) {
    const data = {
      item: item,
      quantity: quantity,
      exists: true,
      isServerSoldOut: false,
      sold: item.sold || 0 + quantity
    }

    if (this.entries.length === 0) {
      this.entries.push(data);
    } else {
      const index = this.entries.findIndex(x => x.item.id === data.item.id);
      if (index === -1) {
        this.entries.push(data);
      } else if (index !== -1) {
        if (this.entries[index].item.available < (this.entries[index].quantity + quantity)) {
          this.toast.open({
            text: `You cannot put ${this.entries[index].quantity + quantity} ${this.entries[index].item.name} in your cart. There are only ${this.entries[index].item.available} available.`,
            actions: [{
              label: this.labels.btnDismiss,
              callback: () => { }
            }]
          });
          return false;
        }
        this.entries[index].quantity += quantity;
        this.entries[index].item.price = item.price;
        this.entries[index].item.imageUrl = item.imageUrl;
        this.entries[index].item.available = item.available;
        console.log('available');

      }
    }

    this.storage.setCartList(this.entries, this.farmSlug);
    return true;
  }

  addToCart(item, quantity) {
    var qty = Number(quantity);
    // if (this.layout.isSmall()) {
    //   this.dialog.open(AddItemComponent, {
    //     data: {
    //       item,
    //       isClosed: this.isClosed
    //     }
    //   });
    // } else {
      // const quantity = Number(qty);
      if (qty > 0) {
        this.gtm.trackingAddToCart(item,this.farmInfo);
        item.quantity = '';
        // this.cart.addItem(item, quantity);
        if (this.addItem(item, qty)) {
          this.toast.open({
            text: `(${quantity}) ${item.name} added to your cart`,
            actions: [
              {
                label: 'View cart',
                callback: () => {
                  this.router.navigate([CONSTANTS.ROUTES.CART_PUBLIC(this.farmSlug)]);
                }
              }
            ]
          });
        }
      }
    // }


  }

  getIsListView(): boolean {
    return this.isListView;
  }

  setIsListView(isListView: boolean): void {
    this.isListView = isListView;
    this.session.saveOfferViewStyleToCache(isListView);
  }
}
