export const CONSTANTS = {
    // API_ROOT: 'https://farmlisting-staging-api.tend.com/',
    API_ROOT: 'https://retailer-api.tend.com/',
    API_ROOT_STAGING: 'https://staging-api.tend.com/',
    //API_ROOT: 'http://localhost:8080/',
    IMG_PUBLIC_URL: 'images/farmlisting/',
    CATALOG_IMG_URL: 'resources/images/photos/catalog/',
    FARM_IMG_URL: 'resources/images/photos/farmlisting/',
    ROUTES: {
        HOME: '/',
        ABOUT(farmSlug: string) { return `${farmSlug}/about`; },
        CART(farmSlug: string) { return `${farmSlug}/cart`; },
        CART_SUCCESS(farmSlug: string, orderId: number) { return `${farmSlug}/cart/success/${orderId}`; },
        OFFER(farmSlug: string, offerId: number) { return `${farmSlug}/offer/${offerId}`; },
        SETTINGS(farmSlug: string) { return `${farmSlug}/account`; },
        VIEW_ORDER(farmSlug: string, orderId: number) { return `${farmSlug}/orders/${orderId}`; },
        NO_FARM: '/no-farm',
        NO_OFFER: '/no-offer',
        CART_PUBLIC(farmSlug: string) { return `${farmSlug}/store/cart`; },
        PUBLIC_CHECK_OUT(farmSlug: string) { return `${farmSlug}/store/billing`; },
        CART_SUCCESS_PUBLIC(farmSlug: string) { return `${farmSlug}/store/success`; },
        STORE(farmSlug: string) { return `${farmSlug}`; }
    },
    ROUTE_PARAM_KEYS: {
        FARM_SLUG: 'farmSlug',
        OFFER_ID: 'id',
        ORDER_ID: 'orderid'
    },
    LAYOUT_CLASSES: {
        LARGE: 'fl-layout-lg',
        MEDIUM: 'fl-layout-md',
        SMALL: 'fl-layout-sm'
    },
    KEYS: {
        E: 'KeyE',
        subtract: 'NumpadSubtract',
        minus: 'Minus'
    },
    OFFER_STATUS: {
        OPEN: 'Open for ordering',
        CLOSED: 'Closed for ordering'
    },
    DATE_FORMATS: {
        abbreviated: {
            month: 'short',
            day: 'numeric',
            year: 'numeric'
        },
        full: {
            weekday: 'long',
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        }
    },
    STORAGE_KEYS: {
        ACCOUNT: 'ACCOUNT',
        FARM: 'FARM',
        OFFER: 'OFFER',
        CART: 'CART',
        EXPIRATION: 'EXPIRATION',
        OFFER_VIEW_STYLE: 'OFFER_VIEW_STYLE'
    },
    API_ENDPOINT: {
        GET_OFFER: 'farmlisting/offer',
        GET_PUBLIC_OFFER: 'farmlisting/public/offer',
        CREATE_PAYMENT: 'farmlisting/public/order/checkout',
        CREATE_ORDER: 'farmlisting/public/add/orderd2c',
        CHECK_SOLD_OUT: 'farmlisting/public/order/checksoldout',
        GET_PICKUP_LOCATIONS: 'farmlisting/public/pickuplocation',
        GET_COUNTRY: 'farmlisting/public/getcountries',
        GET_STATE: 'farmlisting/public/getstates',
        SEND_ORDER_META_DATA: 'farmlisting/public/square/ordermetadata',
    },
    US_STATE:[
        {name: 'Alabama', value: 'AL' },
        {name: 'Alaska', value: 'AK' },
        // {name: 'America Samoa', value: 'AS' },
        {name: 'Arizona', value: 'AZ' },
        {name: 'Arkansas', value: 'AR' },
        {name: 'Armed Forces Americas', value: 'AA' },
        {name: 'Armed Forces', value: 'AE' },
        {name: 'Armed Forces Pacific', value: 'AP' },
        {name: 'California', value: 'CA' },
        {name: 'Colorado', value: 'CO' },
        {name: 'Connecticut', value: 'CT' },
        {name: 'Delaware', value: 'DE' },
        {name: 'District of Columbia', value: 'DC' },
        {name: 'Federated States of Micronesia', value: 'FM' },
        {name: 'Florida', value: 'FL' },
        {name: 'Georgia', value: 'GA' },
        // {name: 'Guam', value: 'GU' },
        {name: 'Hawaii', value: 'HI' },
        {name: 'Idaho', value: 'ID' },
        {name: 'Illinois', value: 'IL' },
        {name: 'Indiana', value: 'IN' },
        {name: 'Iowa', value: 'IA' },
        {name: 'Kansas', value: 'KS' },
        {name: 'Kentucky', value: 'KY' },
        {name: 'Louisiana', value: 'LA' },
        {name: 'Maine', value: 'ME' },
        {name: 'Marshall Islands', value: 'MH' },
        {name: 'Maryland', value: 'MD' },
        {name: 'Massachusetts', value: 'MA' },
        {name: 'Michigan', value: 'MI' },
        {name: 'Minnesota', value: 'MN' },
        {name: 'Mississippi', value: 'MS' },
        {name: 'Missouri', value: 'MO' },
        {name: 'Montana', value: 'MT' },
        {name: 'Nebraska', value: 'NE' },
        {name: 'Nevada', value: 'NV' },
        {name: 'New Hampshire', value: 'NH' },
        {name: 'New Jersey', value: 'NJ' },
        {name: 'New Mexico', value: 'NM' },
        {name: 'New York', value: 'NY' },
        {name: 'North Carolina', value: 'NC' },
        {name: 'North Dakota', value: 'ND' },
        // {name: 'Northern Mariana Islands', value: 'MP' },
        {name: 'Ohio', value: 'OH' },
        {name: 'Oklahoma', value: 'OK' },
        {name: 'Oregon', value: 'OR' },
        {name: 'Pennsylvania', value: 'PA' },
        // {name: 'Puerto Rico', value: 'PR' },
        {name: 'Palau', value: 'PW' },
        {name: 'Rhode Island', value: 'RI' },
        {name: 'South Carolina', value: 'SC' },
        {name: 'South Dakota', value: 'SD' },
        {name: 'Tennessee', value: 'TN' },
        {name: 'Texas', value: 'TX' },
        {name: 'Utah', value: 'UT' },
        {name: 'Virginia', value: 'VA' },
        // {name: 'Virgin Islands', value: 'VI' },
        {name: 'Vermont', value: 'VT' },
        {name: 'Washington', value: 'WA' },
        {name: 'Wisconsin', value: 'WI' },
        {name: 'West Virginia', value: 'WV' },
        {name: 'Wyoming', value: 'WY' }
    ]
};

export enum ImageResolutions {
    Small = 50,
    Large = 300
}

export enum SortType {
    Ascending = 'ASC',
    Descending = 'DESC'
}

export enum OrderSortProperty {
    Created = 'CREATED',
    InvoiceCode = 'INVOICECODE',
    Account = 'ACCOUNT',
    PaymentAmount = 'PAYMENTAMOUNT',
    PackDate = 'PACKDATE',
    Status = 'STATUS',
    PaymentType = 'PAYMENTTYPE'
}
