import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class GTMService {
    //Services for Ecommerce Tracking
    constructor(
    ) { }

    trackingAddToCart(product :any,farm:any){
        var dataLayer = (<any>window).dataLayer = (<any>window).dataLayer || [];
        dataLayer && dataLayer.push({
            'event': 'add',
            'eventCategory': 'Ecommerce',
            'eventAction': 'Add to Cart',
            'eventLabel': product.name,
            'eventValue':0,
            'nonInteraction': 0,  
            'ecommerce': {
            'currencyCode': (farm['currencyCode'] || 'USD'),
              'add': {  
              'actionField': {
                                 'farmId': farm['id'], 
                                 'farmName':farm['name']}, 
              'products': [{
                'name': product['name'],
                'id': product['id'],
                'price': product['price'],
                'packUnit': product['unit']['abbreviation'],
                'category': (product['itemTypeId'] == 1 ? 'Produce' : (product['itemTypeId'] == 2 ? "Egg" : (product['itemTypeId'] == 3 ? "Meat" : 'Other'))),//API return more data
                'quantity': product['quantity'] }]
              }
            }
            });            
    }

    trackingRemoveCart(product :any,farm:any){
      var dataLayer = (<any>window).dataLayer = (<any>window).dataLayer || [];
        dataLayer && dataLayer.push({
            'event': 'remove',
            'eventCategory': 'Ecommerce',
            'eventAction': 'Remove from cart',
            'eventLabel': product.name,
            'eventValue':0,
            'nonInteraction': 0,  
            'ecommerce': {
            'currencyCode': (farm['currencyCode'] || 'USD'),
              'remove': {  
              'actionField': {
                                'farmId': farm['id'], 
                                'farmName':farm['name']}, 
              'products': [{
                'name': product['name'],
                'id': product['id'],
                'price': product['price'],
                'packUnit': product['unit']['abbreviation'],
                'category': (product['itemTypeId'] == 1 ? 'Produce' : (product['itemTypeId'] == 2 ? "Egg" : (product['itemTypeId'] == 3 ? "Meat" : 'Other'))),//API return more data
                'quantity': product['quantity'] }]
              }
            }
            });            
    }

    trackingCheckout(carts :any,farm:any,stepnumber:any,stepname:any){
      var dataLayer = (<any>window).dataLayer = (<any>window).dataLayer || [];
      var products = [];
      carts.forEach((cart, index) => {
        var product = cart.item;
        products.push({
          'name': product['name'],
          'id': product['id'],
          'price': product['price'],
          'packUnit': product['unit']['abbreviation'],
          'category': (product['itemTypeId'] == 1 ? 'Produce' : (product['itemTypeId'] == 2 ? "Egg" : (product['itemTypeId'] == 3 ? "Meat" : 'Other'))),//API return more data
          'quantity': cart['quantity'] });
      });
        dataLayer && dataLayer.push({
            'event': 'checkout',
            'eventCategory': 'Ecommerce',
            'eventAction': 'Checkout Steps',
            'eventLabel': 'Step ' + stepnumber + ' - ' + stepname,
            'eventValue':0,
            'nonInteraction': 1,  
              'ecommerce': {
              'currencyCode': (farm['currencyCode'] || 'USD'),
                'checkout': {  
                'actionField': {'step': stepnumber,'farmId': farm['id'], 'farmName':farm['name']}}, 
                'products': products
              }
            });
                  
  }

  trackingPurchase(carts :any,farm:any,total:any,tax:any,shipping:any,orderid:any){
    var dataLayer = (<any>window).dataLayer = (<any>window).dataLayer || [];
    var products = [];
    carts.forEach((cart, index) => {
      var product = cart.item;
      products.push({
        'name': product['name'],
        'id': product['id'],
        'price': product['price'],
        'packUnit': product['unit']['abbreviation'],
        'category': (product['itemTypeId'] == 1 ? 'Produce' : (product['itemTypeId'] == 2 ? "Egg" : (product['itemTypeId'] == 3 ? "Meat" : 'Other'))),//API return more data
        'quantity': cart['quantity'] });
    });
      dataLayer && dataLayer.push({
          'event': 'purchase',
          'eventCategory': 'Ecommerce',
          'eventAction': 'Purchase',
          'eventLabel': 'Completed',
          'eventValue':total,
          'nonInteraction': 1,  
            'ecommerce': {
            'currencyCode': (farm['currencyCode'] || 'USD'),
              'purchase': {  
              'actionField': {'id': orderid,'farmId': farm['id'], 'farmName':farm['name'],'revenue':0,'tax':0,'shipping':0}}, 
              'products': products
            }
          });
                
}



}
