import { Pipe, PipeTransform } from '@angular/core';
import { SessionService } from '../services/session.service';
import { Farm } from '../model/farm';
import { ImageResolutions, CONSTANTS } from '../model/enums';
import { DataStorageService } from 'src/app/services/storage.service';

@Pipe({
    name: 'farmImagePublic'
})
export class FarmImagePublicPipe implements PipeTransform {

    // farmId: number;

    constructor(private session: SessionService, private storage: DataStorageService) {
        // this.farmId = this.storage.getFarm().id;
    }

    transform(imageUrl: string, resolution?: ImageResolutions): string {
        const slug = window.location.pathname.split('/')[1];
        if (resolution) {
            const resolutionString = resolution.toString() + 'x' + resolution.toString();
            return CONSTANTS.API_ROOT_STAGING + CONSTANTS.IMG_PUBLIC_URL + this.storage.getFarm(slug).id
                + '/thumbnails/' + imageUrl.replace('.jpg', '_' + resolutionString + '.jpg');
        } else {
            return imageUrl;
        }
    }

}
