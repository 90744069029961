export interface IAccount {
    buyerAccountId: number;
    buyerUserId: number;
    email: string;
    firstName: string;
    lastName: string;
    loginToken: string;
}

export class Account implements IAccount {
    buyerAccountId: number;
    buyerUserId: number;
    email: string;
    firstName: string;
    lastName: string;
    loginToken: string;
    name: string;
    constructor(account: IAccount) {
        Object.assign(this, account);
    }

    getName() {
        return this.firstName + (this.lastName ? (' ' + this.lastName) : '');
    }
}
