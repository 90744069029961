import { Component, OnInit, ElementRef } from '@angular/core';
import { IModalDialog } from '../../generic/modal/modal.component';
import { MatDialogRef } from '@angular/material/dialog';
import { HttpService } from 'src/app/services/http.service';
import { IChangeNameRequest } from 'src/app/model/requests/change-name-request';
import { ToastService } from 'src/app/services/toast.service';
import { IChangeNameResponse } from 'src/app/model/responses/change-name-response';
import { SessionService } from 'src/app/services/session.service';
import { Account } from 'src/app/model/account';

@Component({
    selector: 'app-change-name',
    templateUrl: './change-name.component.html',
    styleUrls: ['./change-name.component.less']
})
export class ChangeNameComponent implements IModalDialog, OnInit {

    labels = {
        lblChangeName: 'Change name',
        lblFirstName: 'First name',
        lblLastName: 'Last name',
        lblNameChanged: 'Your name has been updated.',
        lblFailed: 'Something went wrong. Unable to update your name.',
        lblDismiss: 'Dismiss',
        btnCancel: 'Cancel',
        btnSave: 'Save'
    };

    firstName: string;
    lastName: string;

    saveButton: HTMLElement;

    constructor(public dialogRef: MatDialogRef<ChangeNameComponent>, private http: HttpService,
                private session: SessionService, private toast: ToastService, private element: ElementRef) { }

    ngOnInit() {
        this.saveButton = this.element.nativeElement.querySelector('.btnSave');
        this.firstName = this.session.getAccount().firstName;
        this.lastName = this.session.getAccount().lastName;
    }

    canClose(): boolean {
        return true;
    }

    isMinor(): boolean {
        return true;
    }

    close() {
        this.dialogRef.close();
    }

    enterPressed() {
        this.saveButton.click();
    }

    changeName() {
        this.http.post<IChangeNameRequest, IChangeNameResponse>('farmlisting/settings/changeName', {
            firstName: this.firstName,
            lastName: this.lastName
        }).subscribe(() => {
            const account: Account = this.session.getAccount();
            account.firstName = this.firstName;
            account.lastName = this.lastName;
            this.toast.open({
                text: this.labels.lblNameChanged,
                actions: [
                    {
                        label: this.labels.lblDismiss,
                        callback: () => { }
                    }
                ]
            });
            this.close();
        }, () => {
            this.toast.open({
                text: this.labels.lblFailed,
                isError: true,
                actions: [{
                    label: this.labels.lblDismiss,
                    callback: () => {}
                }]
            });
        });
    }

}
