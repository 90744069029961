import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
// tslint:disable-next-line:ordered-imports

@Injectable()
export class DataStorageService {
    public loading = new Subject<boolean>();
    constructor(
    ) { }
    private cartList: any;

    private farmObj: any;

    private totalObj: any;

    private note:any;

    private pickupId:any;

    private billingInfo:any;

    private order:any;

    public cartChange = new Subject();

    //Common Function
    saveData(storagename:string,slug:string,data:any){
        var obj = window.localStorage.getItem(slug) ? JSON.parse(window.localStorage.getItem(slug)) : {};
        obj[storagename] = data;
        window.localStorage.setItem(slug, JSON.stringify(obj));
    }

    readData(storagename:string,slug:string){
        var obj = window.localStorage.getItem(slug) ? JSON.parse(window.localStorage.getItem(slug)) : {};
        return obj && obj[storagename] ? obj[storagename] : {};
    }

    removeData(storagename:string,slug:string){
        this.saveData(storagename,slug,null);
    }

    removeAll(slug:string){
        window.localStorage.removeItem(slug);
    }

    //End Common Function

    //Cart Info
    getCartList(slug:string) {
        const obj = this.readData('public_cart',slug);
        if(obj && Object.keys(obj).length === 0) this.cartList = [];
        else this.cartList = obj;
        return this.cartList;
    }

    resetCartStorage(slug:string) {
        this.cartList = [];
        this.removeData('public_cart',slug);
    }

    setCartList(list:any,slug:string) {
        this.cartList = list || [];
        this.cartChange.next(this.cartList);
        this.saveData('public_cart',slug,list);
    }
    //End Cart Info

    //Farm Info
    getFarm(slug:string) {
        this.farmObj = this.readData('currentFarm',slug);
        return this.farmObj;
    }

    resetFarmStorage(slug:string) {
        this.farmObj = {};
        this.removeData('currentFarm',slug);
    }

    setFarm(list:any,slug:string) {
        this.farmObj = list || {};
        this.saveData('currentFarm',slug,list);
    }
    //End Farm Info

    //Total storage
    getTotal(slug:string) {
        this.totalObj = this.readData('total_cart',slug);
        return this.totalObj;
    }

    resetTotalStorage(slug:string) {
        this.totalObj = {};
        this.removeData('total_cart',slug);
    }

    setTotal(slug:string,list:any) {
        this.totalObj = list || {};
        this.saveData('total_cart',slug,this.totalObj);
    }

    updateCartOrderId(id:string,slug:string){
        this.totalObj = this.totalObj || {};
        this.totalObj.orderid = id;
        this.saveData('total_cart',slug,this.totalObj);
    }
    //End total info storage

    //Farm Info
    getNote(slug:string) {
      this.note = this.readData('note',slug);
      return this.note;
    }

    resetNote(slug:string) {
      this.note = {};
      this.removeData('note',slug);
    }

    setNote(note:any,slug:string) {
      this.note = note || {};
      this.saveData('note',slug,note);
    }
    //End Farm Info
    //Farm Info
    getBillingInfo(slug:string) {
        this.billingInfo = this.readData('billinginfo',slug);
        return this.billingInfo;
      }

      resetBillingInfo(slug:string) {
        this.billingInfo = {};
        this.removeData('billinginfo',slug);
      }

      setBillingInfo(info:any,slug:string) {
        this.billingInfo = info || {};
        this.saveData('billinginfo',slug,info);
      }
      //End Farm Info

      //pickup location
      getpickupId(slug:string) {
        this.pickupId = this.readData('pickupId',slug);
        return this.pickupId;
      }

      resetpickupId(slug:string) {
        this.pickupId = {};
        this.removeData('pickupId',slug);
      }

      setpickupId(pickupId:any,slug:string) {
        this.pickupId = pickupId || {};
        this.saveData('pickupId',slug, pickupId);
      }
      //end pickup location
      //pickup location
      getOrder(slug:string) {
        this.order = this.readData('order',slug);
        return this.order;
      }

      resetOrder(slug:string) {
        this.order = {};
        this.removeData('order',slug);
      }

      setOrder(order:any,slug:string) {
        this.order = order || {};
        this.saveData('order',slug, order);
      }
      //end pickup location
}
