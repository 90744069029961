import { Component, OnInit, Input } from '@angular/core';
import { LayoutService } from 'src/app/services/layout.service';

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.less']
})
export class NotFoundComponent implements OnInit {

    @Input() icon: string;
    @Input() heading: string;
    @Input() message: string;

    constructor(public layout: LayoutService) { }

    ngOnInit() {
    }

}
