import * as moment from 'moment';

export interface IOrder {
    id: number;
    farmid: number;
    orderdate: Date;
    createddate: Date;
    customerid: number;
    customer: string;
    paymentamount: number;
    paymentamountreceived: number;
    shippingcost: number;
    taxcost: number;
    packdate: Date;
    paymentmethodid: number;
    paymentmethod: string;
    pickuplocationid: number;
    orderstatusid: number;
    orderstatus: string;
    ordertype: string;
    offersheetid: number;
    paymentdate: Date;
    paymentdue: Date;
    invoicedate: Date;
    note: string;
    pickpacklistid: number;
    currencyid: number;
    currencysymbol: string;
    currencyname: string;
    invoicecode: string;
    subtotalprice: number;
    deliverydate: Date;
    havenote: boolean;

    itemdetail: IOrderDetail[];
    refunds: IOrderRefund[];
}

export interface IOrderDetail {
    orderid: number;
    itemid: number;
    itemdetailid: number;
    quantity: number;
    itemname: string;
    itemdetailunitid: number;
    itemdetailunitname: string;
    itemdetailunitabbreviation: string;
    shippedquantity: number;
    price: number;
    subtotalprice: number;
    harveststageid: number;
    itemcategoryid: number;
    parentcropid: number;
    varietyid: number;
    description: string;
    image: string;
    totalprice: number;
    currencyid: number;
    currencyname: string;
}

export interface IOrderRefund {
    orderadjustmentid: number;
    amount: number;
}

export class Order {
    id: number;
    status: string;
    orderDate: string;
    tax: number;
    shipping: number;
    total: number;
    paymentDueDate: string;
    deliveryDate: string;
    pickupLocationId: number;
    note: string;

    items: OrderDetail[];
    refunds: OrderRefund[];

    private dateFormat = 'MMMM D, YYYY';

    constructor(order: IOrder) {
        this.id = order.id;
        this.status = order.orderstatus;
        this.orderDate = order.orderdate ? moment(new Date(order.orderdate)).format(this.dateFormat) : undefined;
        this.tax = order.taxcost || 0;
        this.shipping = order.shippingcost || 0;
        this.total = order.paymentamount || 0;
        this.paymentDueDate = order.paymentdue ? moment(new Date(order.paymentdue)).format(this.dateFormat) : undefined;
        this.deliveryDate = order.deliverydate ? moment(new Date(order.deliverydate)).format(this.dateFormat) : undefined;
        this.pickupLocationId = order.pickuplocationid;
        this.note = order.note;

        this.items = order.itemdetail ? order.itemdetail.map((item) => new OrderDetail(item)) : [];
        this.refunds = order.refunds ? order.refunds.map((refund) => new OrderRefund(refund)) : [];
    }

    getSubtotal() {
        return this.items ? this.items.reduce((subtotal: number, item) => {
            return subtotal + item.price;
        }, 0) : 0;
    }

    getRefund() {
        return this.refunds ? this.refunds.reduce((refundAmount: number, refund) => {
            return refundAmount + refund.amount;
        }, 0) : 0;
    }

}

export class OrderDetail {
    id: number;
    catalogItemId: number;
    itemCategoryId: number;
    name: string;
    imageUrl: string;
    unit: string;
    unitAbbreviation: string;
    quantity: number;
    price: number;

    constructor(item: IOrderDetail) {
        this.id = item.itemdetailid;
        this.catalogItemId = item.itemid;
        this.itemCategoryId = item.itemcategoryid;
        this.name = item.itemname;
        this.imageUrl = item.image;
        this.unit = item.itemdetailunitname;
        this.unitAbbreviation = item.itemdetailunitabbreviation;
        this.quantity = item.quantity;
        this.price = item.price * item.quantity;
    }
}

export class OrderRefund {
    id: number;
    amount: number;

    constructor(refund: IOrderRefund) {
        this.id = refund.orderadjustmentid;
        this.amount = refund.amount;
    }

}
