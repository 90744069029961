import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LayoutModule } from '@angular/cdk/layout';
import { TextFieldModule } from '@angular/cdk/text-field';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatSelectModule } from '@angular/material/select';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatSidenavModule, MatListModule } from "@angular/material";

import { AboutComponent } from './components/about/about.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { OfferComponent } from './components/offer/offer/offer.component';

import { CartComponent } from './components/cart/cart/cart.component';
import { CurrencyComponent } from './components/generic/currency/currency.component';
import { NumberDirective } from './directives/number.directive';
import { NumberOnlyDirective } from './directives/numberOnly.directive';
import { LoginComponent } from './components/login/login/login.component';
import {
    ModalComponent, ModalHeaderDirective, ModalContentDirective,
    ModalFooterDirective
} from './components/generic/modal/modal.component';
import { ForgotPasswordComponent } from './components/login/forgot-password/forgot-password.component';
import { ToastComponent } from './components/generic/toast/toast.component';
import { ResetPasswordComponent } from './components/login/reset-password/reset-password.component';
import { OrderSuccessComponent } from './components/cart/order-success/order-success.component';
import { AddItemComponent } from './components/offer/add-item/add-item.component';
import { NoFarmComponent } from './components/not-found/no-farm/no-farm.component';
import { NoOfferComponent } from './components/not-found/no-offer/no-offer.component';
import { NotFoundComponent } from './components/generic/not-found/not-found.component';
import { AssetImagePipe } from './pipes/asset-image.pipe';
import { CatalogPlaceholderPipe } from './pipes/catalog-placeholder.pipe';
import { CatalogImagePipe } from './pipes/catalog-image.pipe';
import { FarmImagePipe } from './pipes/farm-image.pipe';
import { FarmImagePublicPipe } from './pipes/farm-image-public.pipe';
import { ExternalUrlPipe } from './pipes/external-url.pipe';
import { AuthInterceptor } from './interceptors/auth-interceptor';
import { HtmlSafeDirective } from './directives/html-safe.directive';
import { SettingsComponent } from './components/settings/settings/settings.component';
import { ChangePasswordComponent } from './components/settings/change-password/change-password.component';
import { ChangeEmailComponent } from './components/settings/change-email/change-email.component';
import { ChangeNameComponent } from './components/settings/change-name/change-name.component';
import { EmptyPipe } from './pipes/empty.pipe';
import { ViewOrderComponent } from './components/settings/view-order/view-order.component';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { StoreBillingComponent } from './components/public/billing/billing.component';
import { StoreComponent } from './components/public/store/store.component';
import { DataStorageService } from 'src/app/services/storage.service';
import { CartPublicComponent } from './components/public/cart/cart.component';
import { OrderSuccessPublicComponent } from './components/public/order-success/order-success.component';
import { SharedModule } from './shared/shared.module';
import { DeviceDetectorModule } from 'ngx-device-detector';

@NgModule({
    declarations: [
        AppComponent,
        AboutComponent,
        ToolbarComponent,
        OfferComponent,
        CartComponent,
        CurrencyComponent,
        NumberDirective,
        NumberOnlyDirective,
        LoginComponent,
        ModalComponent,
        ModalHeaderDirective,
        ModalContentDirective,
        ModalFooterDirective,
        ForgotPasswordComponent,
        ToastComponent,
        ResetPasswordComponent,
        OrderSuccessComponent,
        OrderSuccessPublicComponent,
        AddItemComponent,
        NoFarmComponent,
        NoOfferComponent,
        NotFoundComponent,
        AssetImagePipe,
        CatalogPlaceholderPipe,
        CatalogImagePipe,
        FarmImagePipe,
        ExternalUrlPipe,
        HtmlSafeDirective,
        SettingsComponent,
        ChangePasswordComponent,
        ChangeEmailComponent,
        ChangeNameComponent,
        EmptyPipe,
        ViewOrderComponent,
        StoreBillingComponent,
        StoreComponent,
        CartPublicComponent,
        FarmImagePublicPipe
    ],
    entryComponents: [
        LoginComponent,
        ForgotPasswordComponent,
        ToastComponent,
        AddItemComponent,
        ChangePasswordComponent,
        ChangeEmailComponent,
        ChangeNameComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        RouterModule,
        BrowserAnimationsModule,
        MatToolbarModule,
        MatTabsModule,
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatProgressSpinnerModule,
        MatCheckboxModule,
        LayoutModule,
        TextFieldModule,
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatSnackBarModule,
        HttpClientModule,
        NgbModule,
        MatSelectModule,
        SharedModule,
        NgSelectModule,
        MatSidenavModule,
        MatListModule,
        DeviceDetectorModule.forRoot(),
    ],
    bootstrap: [AppComponent],
    providers: [ DataStorageService, { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true } ],
    exports: [NumberOnlyDirective]
})
export class AppModule { }
