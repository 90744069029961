import { Component, OnInit, ElementRef } from '@angular/core';
import { IModalDialog } from '../../generic/modal/modal.component';
import { MatDialogRef } from '@angular/material/dialog';
import { HttpService } from 'src/app/services/http.service';
import { IChangeEmailResponse, EnumChangeEmailStatus } from 'src/app/model/responses/change-email-response';
import { IChangeEmailRequest } from 'src/app/model/requests/change-email-request';
import { SessionService } from 'src/app/services/session.service';
import { ToastService } from 'src/app/services/toast.service';
import { Account } from 'src/app/model/account';

@Component({
    selector: 'app-change-email',
    templateUrl: './change-email.component.html',
    styleUrls: ['./change-email.component.less']
})
export class ChangeEmailComponent implements IModalDialog, OnInit {

    labels = {
        lblChangeEmail: 'Change email',
        lblNewEmail: 'New email address',
        lblCurrentPassword: 'Current password',
        lblEmailChanged: 'Your email has been updated.',
        lblFailed: 'Something went wrong. Unable to update your email.',
        lblDismiss: 'Dismiss',
        lblEmailInvalid: 'This email address is invalid',
        lblAlreadyInUse: 'This email address is already in use',
        lblCredentialsFailed: 'This does not match your current password',
        btnCancel: 'Cancel',
        btnSave: 'Save'
    };

    newEmail: string;
    currentPassword: string;

    wasEmailInvalid = false;
    wasEmailAlreadyInUse = false;
    haveCredentialsFailed = false;

    saveButton: HTMLElement;

    constructor(public dialogRef: MatDialogRef<ChangeEmailComponent>, private http: HttpService,
                private session: SessionService, private toast: ToastService, private element: ElementRef) { }

    ngOnInit() {
        this.saveButton = this.element.nativeElement.querySelector('.btnSave');
    }

    canClose(): boolean {
        return true;
    }

    isMinor(): boolean {
        return true;
    }

    close() {
        this.dialogRef.close();
    }

    enterPressed() {
        this.saveButton.click();
    }

    changeEmail() {
        this.http.post<IChangeEmailRequest, IChangeEmailResponse>('farmlisting/settings/changeEmail', {
            newEmail: this.newEmail,
            currentPassword: btoa(this.currentPassword)
        }).subscribe((response: any) => {
            const loggedInAccount: Account = this.session.getAccount();
            loggedInAccount.loginToken = response.loginToken;
            loggedInAccount.email = this.newEmail;
            this.toast.open({
                text: this.labels.lblEmailChanged,
                actions: [
                    {
                        label: this.labels.lblDismiss,
                        callback: () => { }
                    }
                ]
            });
            this.close();
        }, (response: IChangeEmailResponse) => {
            this.wasEmailInvalid = false;
            this.wasEmailAlreadyInUse = false;
            this.haveCredentialsFailed = false;
            if (response && response.status === EnumChangeEmailStatus.INVALID_EMAIL) {
                this.wasEmailInvalid = true;
            } else if (response && response.status === EnumChangeEmailStatus.ALREADY_IN_USE) {
                this.wasEmailAlreadyInUse = true;
            } else if (response && response.status === EnumChangeEmailStatus.BAD_CREDENTIALS) {
                this.haveCredentialsFailed = true;
            } else {
                this.toast.open({
                    text: this.labels.lblFailed,
                    isError: true,
                    actions: [{
                        label: this.labels.lblDismiss,
                        callback: () => {}
                    }]
                });
            }
        });
    }

}
