import { Component, OnInit, ViewChild } from '@angular/core';
import { LayoutService } from '../../services/layout.service';
import { CONSTANTS } from '../../model/enums';
import { SessionService } from 'src/app/services/session.service';
import { Farm } from 'src/app/model/farm';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { DataStorageService } from 'src/app/services/storage.service';
import { filter } from 'rxjs/operators';
import {Title} from "@angular/platform-browser";

@Component({
    selector: 'app-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.less']
})
export class ToolbarComponent implements OnInit {

    CONSTANTS = CONSTANTS;
    labels = {
        tabOffer: 'Offer',
        tabAbout: 'About us',
        tabStore: 'Store'
    };
    tabs = [
    //     {
    //         path: CONSTANTS.ROUTES.NO_OFFER,
    //         label: this.labels.tabOffer
    //     },
    //     {
    //         path: CONSTANTS.ROUTES.NO_FARM,
    //         label: this.labels.tabAbout
    //     }
    ];
    tabsNoLogin = [
        {
            path: '',
            label: this.labels.tabStore
        },
        {
            path: '',
            label: this.labels.tabAbout
        }
    ];
    farmLogo: string;
    farmName: string;
    farmSlug: string;
    phone: string;
    email: string;
    website: string;
    isPrivate: boolean = false;
    selectedIndex: number = 0;
    public isInNoOfferScreen = false;
    public isInNoFarmScreen = false;
    navbarOpen = false;
    constructor(public layout: LayoutService, public session: SessionService,
        public router: Router, public route: ActivatedRoute,
        private storage: DataStorageService, private titleService:Title) {
        this.router.events.pipe(
            filter(e => e instanceof NavigationStart)
        ).subscribe((e: NavigationStart ) => {
            // TODO: No ticket here, check if any issue about navigating between About, Store
            this.setTabWithoutLogin();
            // Add more empty screen if any
            if(e.url.startsWith('/no-offer')) {
                this.isInNoOfferScreen = true;
                return;
            }
            else if(e.url.startsWith('/no-farm')) {
                this.isInNoFarmScreen = true;
                return;
            }
            this.isInNoOfferScreen = false;
            this.isInNoFarmScreen = false;

        });

    }

    ngOnInit() {
        this.session.getFarm().subscribe((farm: Farm) => {
            if (farm) {
                this.farmName = farm.name;
                this.farmSlug = farm.slug;
                this.farmLogo = farm.logoUrl;
                this.phone = farm.phone;
                this.email = farm.email;
                this.website = farm.website;
                if (window.location.pathname.indexOf('offer') !== -1) {
                    this.session.getOffer().subscribe((offer) => {
                        if (offer) {
                            this.isPrivate = true;
                        }
                    });
                    this.tabs = [
                        {
                            path: this.farmSlug,
                            label: this.labels.tabAbout
                        }
                    ];
                    this.selectedIndex = 0;

                } else {
                    this.tabs = [
                        {
                            path: CONSTANTS.ROUTES.STORE(this.farmSlug),
                            label: this.labels.tabStore
                        },
                        {
                            path: this.farmSlug,
                            label: this.labels.tabAbout
                        }
                    ];
                    this.setSelectedMenuItem();
                }
            }
            setTimeout(() => {
                if (!this.isPrivate && !this.session.getIsLoggedIn()) {
                    this.setTabWithoutLogin();
                }
            });
        });
        this.route.queryParams.subscribe(params => {
            const farmSlug = params[CONSTANTS.ROUTE_PARAM_KEYS.FARM_SLUG];
            if(!farmSlug) { return; }

            var farm = this.storage.getFarm(farmSlug);
            if(!farm) { return; }
            this.farmName = farm.name;
            this.farmSlug = farm.slug;
            this.farmLogo = farm.logoUrl;
            this.phone = farm.phone;
            this.email = farm.email;
            this.website = farm.website;
        });
    }

    setTabWithoutLogin() {
      const slug = window.location.pathname.split('/')[1];
      setTimeout(() => {
        const farm = this.storage.getFarm(slug);
        this.tabsNoLogin[0].label = 'Store';
        this.setSelectedMenuItem();
        // Farm has no data
        if(Object.keys(farm).length === 0) {
          return;
        }
        this.tabsNoLogin[0].path = CONSTANTS.ROUTES.STORE(farm.slug);
        this.tabsNoLogin[1].path = CONSTANTS.ROUTES.ABOUT(farm.slug);
        this.farmName = farm.name;
        this.farmSlug = farm.slug;
        this.farmLogo = farm.logoUrl;
        this.phone = farm.phone;
        this.email = farm.email;
        this.website = farm.website;

        const title = `Online Store | ${this.farmName}`;
        this.titleService.setTitle(title);        
      },2000);
    }

    getAboutPath() {
        return `/${CONSTANTS.ROUTES.ABOUT(this.farmSlug)}`;
    }

    setSelectedMenuItem() {
        this.selectedIndex = 1;
        if (window.location.pathname.indexOf('about') === -1) {
            this.selectedIndex = 0;
        }
    }

    onWebsiteClicked() {
        var openingUrl = this.website.startsWith('http')
            ? this.website : `http://${this.website}`;
        window.open(openingUrl, 'blank');
    }

    toggleNavbar() {
      this.navbarOpen = !this.navbarOpen;
    }
}
