import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CartService } from 'src/app/services/cart.service';
import { SessionService } from 'src/app/services/session.service';
import { CONSTANTS } from 'src/app/model/enums';
import { LayoutService } from 'src/app/services/layout.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastService } from 'src/app/services/toast.service';
import { HttpService } from 'src/app/services/http.service';
import { Offer, OfferItem } from 'src/app/model/offer';
import { Subscription } from 'rxjs';
import { StoreService } from 'src/app/services/store.service';
import { environment } from 'src/environments/environment';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { PaymentService } from 'src/app/services/payment.service';
import { Farm } from 'src/app/model/farm';
import { DataStorageService } from 'src/app/services/storage.service';
import { GTMService } from 'src/app/services/GTM.service';
import { DeviceDetectorService } from 'ngx-device-detector';

declare var SqPaymentForm : any;

@Component({
    selector: 'app-billing',
    templateUrl: './billing.component.html',
    styleUrls: ['./billing.component.less']
})
export class StoreBillingComponent implements OnInit, OnDestroy {

    labels = {
        btnAdd: 'Add',
        lblQuantity: 'Quantity',
        lblLogout: 'Log out',
        lblNotStarted: 'This offer has not started',
        placeholderEnterAmount: 'Enter amount',
        lblSubtotal: 'Subtotal',
        lblTax: 'Tax',
        lblShipping: 'Delivery',
        lblTotal: 'Total'
    };
    CONSTANTS = CONSTANTS;

    farmSlug: string;
    form: FormGroup;
    isProcessing:any;

    farm: Farm;
    cartEntries: any = [];
    cartInfo : any;
    orderid : any;
    order : any;
    pickupLocation:any;

    offersheetId: number;
    offer: Offer;
    deliveryHeader: string;
    statusHeader: string;
    statusClass: string;
    timeRemaining: string;
    message: string;
    items: OfferItem[];
    isClosed = true;
    errorLog = {};
    listMaster: any = {
      country: [],
      state: []
    };

    public statusIntervalId: number;
    public logoutSubscription: Subscription;
    paymentForm: any;

    constructor(public cart: CartService, public session: SessionService, private dialog: MatDialog, private storage: DataStorageService,
                public layout: LayoutService, private toast: ToastService, private storeService: StoreService, private router: Router,
                private route: ActivatedRoute, private http: HttpService,public paymentService: PaymentService,private fb: FormBuilder,
                private deviceService: DeviceDetectorService, private gtm:GTMService) {
    }



    ngOnInit() {
        this.form = this.fb.group({
            firstName: ['', [Validators.required]],
            lastName: ['', [Validators.required]],
            email: ['', [Validators.required,Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
            phone: ['', [Validators.required,Validators.pattern(/^[0-9]*$/)]],
            address: ['', [Validators.required]],
            city: ['', [Validators.required]],
            state:[null],
            postal: ['', [Validators.required]],
            billingaddress2:['', Validators.required],
            billingcountrycode:[null, Validators.required],
            billingcountryname:[null],
            shippingsameasbilling:false,
            // postal:['', [Validators.required]]
            // note:['', []]

            firstName1: ['', [Validators.required]],
            lastName1: ['', [Validators.required]],
            email1: ['', [Validators.required,Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
            phone1: ['', [Validators.required,Validators.pattern(/^[0-9]*$/)]],
            address1: ['', [Validators.required]],
            city1: ['', [Validators.required]],
            state1:[null],
            postal1: ['', [Validators.required]],
            shippingaddress2:['', Validators.required],
            shippingcountrycode:[null, Validators.required],
            shippingcountryname:[null],
          });
        //   this.form.get('shippingsameasbilling').valueChanges.subscribe(shippingsameasbilling => {
        //     if (shippingsameasbilling) {
        //         this.form.patchValue({
        //             "firstName1":this.form.get('firstName').value,
        //             "lastName1":this.form.get('lastName').value,
        //             "email1":this.form.get('email').value,
        //             "phone1":this.form.get('phone1').value,
        //             "address1":this.form.get('address').value,
        //             "city1":this.form.get('city').value,
        //             "state1":this.form.get('state').value
        //         });
        //         // this.form.get('state').reset();
        //         this.form.get('firstName1').disable();
        //         this.form.get('lastName1').disable();
        //         this.form.get('email1').disable();
        //         this.form.get('address1').disable();
        //         this.form.get('city1').disable();
        //         this.form.get('state1').disable();
        //     }
        //     else {
        //         this.form.get('firstName1').enable();
        //         this.form.get('lastName1').enable();
        //         this.form.get('email1').enable();
        //         this.form.get('address1').enable();
        //         this.form.get('city1').enable();
        //         this.form.get('state1').enable();
        //     }
        // });

          var that = this;
        this.route.paramMap.subscribe((params) => {
            this.farmSlug = params.get(CONSTANTS.ROUTE_PARAM_KEYS.FARM_SLUG);
            this.cartEntries = this.storage.getCartList(this.farmSlug) || [];
            const farm = this.storage.getFarm(this.farmSlug) || [];
            this.farm = farm;
            this.cartInfo = this.storage.getTotal(this.farmSlug) || [];
            this.orderid = this.cartInfo.orderid;
            this.order = this.storage.getOrder(this.farmSlug) || {};
            this.pickupLocation = this.storage.getpickupId(this.farmSlug) || {};
            if(this.pickupLocation && !this.pickupLocation.isdefault){
                this.form.get('firstName1').disable();
                this.form.get('lastName1').disable();
                this.form.get('email1').disable();
                this.form.get('phone1').disable();
                this.form.get('address1').disable();
                this.form.get('city1').disable();
                this.form.get('postal1').disable();
                this.form.get('shippingaddress2').disable();
                this.form.get('shippingcountrycode').disable();
            }

            this.gtm.trackingCheckout(this.cartEntries,this.farm,2,'Checkout');

            if (this.orderid !== this.cartInfo.orderid) {
              console.log('Error not found cart!!!');
            }

            const billingInfo = this.storage.getBillingInfo(this.farmSlug) || {};
            this.form.patchValue({
                            "firstName":billingInfo.billingfirstname,
                            "lastName":billingInfo.billinglastname,
                            "email":billingInfo.billingemail,
                            "address":billingInfo.billingaddress,
                            "city":billingInfo.billingcity,
                            "state":billingInfo.billingstate || null,
                            "phone":billingInfo.billingphone,
                            "postal":billingInfo.billingpostalcode,
                            "billingaddress2": billingInfo.billingaddress2,
                            "billingcountrycode": billingInfo.billingcountrycode,
                            "billingcountryname": billingInfo.billingcountryname,

                            "shippingsameasbilling":billingInfo.shippingsameasbilling,

                            "firstName1":billingInfo.shippingfirstname,
                            "lastName1":billingInfo.shippinglastname,
                            "email1":billingInfo.shippingemail,
                            "address1":billingInfo.shippingaddress,
                            "city1":billingInfo.shippingcity,
                            "state1":billingInfo.shippingstate || null,
                            "phone1":billingInfo.shippingphone,
                            "postal1":billingInfo.shippingpostalcode,
                            "shippingaddress2": billingInfo.shippingaddress2,
                            "shippingcountrycode": billingInfo.shippingcountrycode,
                            "shippingcountryname": billingInfo.shippingcountryname
                        });
                        if (billingInfo.billingcountrycode === 'US' || billingInfo.billingcountrycode === 'CA') {
                          this.form.get('state').setValidators([Validators.required]);
                          this.paymentService.getState().subscribe(res => {
                            this.listMaster['stateBilling'] = (billingInfo.billingcountrycode === 'US') ? res[1]['states'] : res[0]['states'];
                          }, (err) => {
                            console.log(err);
                          });
                        }

                        if (billingInfo.shippingcountrycode === 'US' || billingInfo.shippingcountrycode === 'CA') {
                          this.form.get('state1').setValidators([Validators.required]);
                          this.paymentService.getState().subscribe(res => {
                            this.listMaster['stateDelivery'] = (billingInfo.shippingcountrycode === 'US') ? res[1]['states'] : res[0]['states'];
                          }, (err) => {
                            console.log(err);
                          });
                        }
        });

        this.createPaymentForm();
        this.getListCountry();
        this.paymentForm.build();
    }

    getListCountry() {
      this.paymentService.getCountry().subscribe(res => {
        this.listMaster['country'] = res;
      }, (err) => {
        console.log(err);

      });
    }

    changeCountryBilling(event) {
      this.form.patchValue({billingcountryname: event.countryname});
      if (this.form.value.billingcountrycode === 'US' || this.form.value.billingcountrycode === 'CA') {
        this.form.get('state').setValidators([Validators.required]);
        this.form.controls['state'].updateValueAndValidity();
        this.paymentService.getState().subscribe(res => {
          this.listMaster['stateBilling'] = (this.form.value.billingcountrycode === 'US') ? res[1]['states'] : res[0]['states'];
        }, (err) => {
          console.log(err);
        });
      } else {
        this.form.patchValue({state: null});
        this.listMaster['stateBilling'] = [];
        this.form.get('state').clearValidators();
        this.form.get('state').updateValueAndValidity();
      }
    }

    changeCountryDelivery(event) {
      this.form.patchValue({shippingcountryname: event.countryname});
      if (this.form.value.shippingcountrycode === 'US' || this.form.value.shippingcountrycode === 'CA') {
        this.form.get('state1').setValidators([Validators.required]);
        this.form.controls['state1'].updateValueAndValidity();
        this.paymentService.getState().subscribe(res => {
          this.listMaster['stateDelivery'] = (this.form.value.shippingcountrycode === 'US') ? res[1]['states'] : res[0]['states'];
        }, (err) => {
          console.log(err);
        });
      } else {
        this.form.patchValue({state1: null});
        this.listMaster['stateDelivery'] = [];
        this.form.get('state1').clearValidators();
        this.form.get('state1').updateValueAndValidity();
      }
    }

    isHaveShipping(){
        return this.pickupLocation.isdefault;
    }

    goBack() {
        var that = this;
        this.storage.setBillingInfo({
            billingfirstname:that.form.get('firstName').value,
            billinglastname:that.form.get('lastName').value,
            billingaddress:that.form.get('address').value,
            billingcity:that.form.get('city').value,
            billingstate:that.form.get('state').value,
            billingemail:that.form.get('email').value,
            billingphone:that.form.get('phone').value,
            billingpostalcode:that.form.get('postal').value,
            billingaddress2:that.form.get('billingaddress2').value,
            billingcountrycode:that.form.get('billingcountrycode').value,
            billingcountryname:that.form.get('billingcountryname').value,
            shippingsameasbilling:that.form.get('shippingsameasbilling').value,

            shippingfirstname:that.form.get('firstName1').value,
            shippinglastname:that.form.get('lastName1').value,
            shippingaddress:that.form.get('address1').value,
            shippingcity:that.form.get('city1').value,
            shippingstate:that.form.get('state1').value,
            shippingemail:that.form.get('email1').value,
            shippingphone:that.form.get('phone1').value,
            shippingpostalcode:that.form.get('postal1').value,
            shippingaddress2:that.form.get('shippingaddress2').value,
            shippingcountrycode:that.form.get('shippingcountrycode').value,
            shippingcountryname:that.form.get('shippingcountryname').value,
        },this.farmSlug);
        window.history.back();
    }

    createPaymentForm(){
        var that = this;
        // Set the application ID
        var applicationId = environment.SQUARE_APPLICATION_ID;

        // Set the location ID
        // var locationId = "CBASELjav8kAOzgP4SZlbX46e_IgAQ";
        this.paymentForm = new SqPaymentForm({

        // Initialize the payment form elements
        applicationId: applicationId,
        // locationId: locationId,
        inputClass: 'sq-input',
        autoBuild: false,
        // Customize the CSS for SqPaymentForm iframe elements
        inputStyles: [{
            fontSize: '14px',
            lineHeight: '32px',
            //boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075)',
            color: 'rgba(0,0,0,0.87)',
            padding: '0',
            backgroundColor: '#fff',
        }],



        // Initialize the credit card placeholders
        cardNumber: {
            elementId: 'sq-card-number',
            placeholder: 'Card Number'
        },
        cvv: {
            elementId: 'sq-cvv',
            placeholder: 'CVV'
        },
        expirationDate: {
            elementId: 'sq-expiration-date',
            placeholder: 'MM/YY'
        },
        postalCode: {
            elementId: 'sq-postal-code',
            placeholder: 'Postal Code'
        },

        // SqPaymentForm callback functions
        callbacks: {

            /*
            * callback function: methodsSupported
            * Triggered when: the page is loaded.
            */
            methodsSupported: function (methods) {

            },

            /*
            * callback function: cardNonceResponseReceived
            * Triggered when: SqPaymentForm completes a card nonce request
            */
            cardNonceResponseReceived: function (errors, nonce, cardData)  {
            if (errors) {
                // Log errors from nonce generation to the Javascript console
                // console.log("Encountered errors:");
                that.errorLog = {};
                errors.forEach(function(error) {
                // console.log('  ' + error.message);
                    that.errorLog[error.field] = error.message;
                });
                // debugger;

                // errors.forEach(function(error) {
                //     $(paymentInfo[error.field].selector)
                //         .addClass("sq-input--error")
                //         .parent().addClass("hasError")
                //         .find(".error").text(paymentInfo[error.field].message);
                // });

                return;
            }
            if(that.isProcessing)return;
            that.isProcessing = true;

            const data = {
                billingfirstname:that.form.get('firstName').value,
                billinglastname:that.form.get('lastName').value,
                billingaddress:that.form.get('address').value,
                billingcity:that.form.get('city').value,
                billingstate:that.form.get('state').value,
                billingemail:that.form.get('email').value,
                billingphone:that.form.get('phone').value,
                billingpostalcode:that.form.get('postal').value,
                billingaddress2:that.form.get('billingaddress2').value,
                billingcountrycode:that.form.get('billingcountrycode').value,
                billingcountryname:that.form.get('billingcountryname').value,
                // note:that.form.get('note').value

                shippingfirstname:that.form.get('firstName1').value,
                shippinglastname:that.form.get('lastName1').value,
                shippingaddress:that.form.get('address1').value,
                shippingcity:that.form.get('city1').value,
                shippingstate:that.form.get('state1').value,
                shippingemail:that.form.get('email1').value,
                shippingphone:that.form.get('phone1').value,
                shippingpostalcode:that.form.get('postal1').value,
                shippingaddress2:that.form.get('shippingaddress2').value,
                shippingcountrycode:that.form.get('shippingcountrycode').value,
                shippingcountryname:that.form.get('shippingcountryname').value
            }
            // alert('Nonce received: ' + nonce); /* FOR TESTING ONLY */
            that.paymentService.createPayment(nonce,that.farm.id,data,that.orderid,that.order).subscribe((result) => {
                that.router.navigate([CONSTANTS.ROUTES.CART_SUCCESS_PUBLIC(that.farmSlug)]);
                that.gtm.trackingCheckout(that.cartEntries,that.farm,3,'Complete');
                that.gtm.trackingPurchase(that.cartEntries,that.farm,that.cartInfo.total,that.cartInfo.tax,that.cartInfo.shipping,result.orderid);
                that.storage.resetCartStorage(that.farmSlug);
                that.storage.resetTotalStorage(that.farmSlug);
                that.storage.resetNote(that.farmSlug);
                that.storage.resetpickupId(that.farmSlug);
                that.storage.resetBillingInfo(that.farmSlug);
                that.storage.resetOrder(that.farmSlug);
                that.paymentService.sendOrderMetaData({
                  orderid: result.id,
                  metadata: JSON.stringify({
                    deviceInfo: that.deviceService.getDeviceInfo(),
                    isMobile: that.deviceService.isMobile(),
                    isTablet: that.deviceService.isTablet(),
                    isDesktopDevice: that.deviceService.isDesktop()
                  })
                }).subscribe(() => {});
            }, (error) => {
                that.errorLog = {};
                if (error.body.meta.code === '45_23_f') {
                    let windowClosedConfig = {
                      text: `There were item(s) in your cart that are no longer available. Please remove them and resubmit your order`,
                      actions: [
                        {
                          label: 'Dismiss',
                          callback: () => {
                          }
                        }
                      ],
                      isError: true,
                      closeX: true
                    };
                    that.toast.open(windowClosedConfig);
                  }else{
                    let windowClosedConfig = {
                        text: `Something went wrong, please try again later or contact us at hello@tend.com`,
                        actions: [
                            {
                                label: 'Dismiss',
                                callback: () => {
                                }
                            }
                        ],
                        isError: true
                    };
                    that.toast.open(windowClosedConfig);
                  }
            });

            // Assign the nonce value to the hidden form field
            // document.getElementById('card-nonce').value = nonce;
            //needs to be extracted from the
            // (<HTMLInputElement>document.getElementById('card-nonce')).value = nonce; //casting so .value will work
            // //get this value from the database when the user is logged in
            // (<HTMLInputElement>document.getElementById('sq-id')).value = "CBASEC8F-Phq5_pV7UNi64_kX_4gAQ";

            // // POST the nonce form to the payment processing page
            // (<HTMLFormElement>document.getElementById('nonce-form')).submit();

            },

            /*
            * callback function: unsupportedBrowserDetected
            * Triggered when: the page loads and an unsupported browser is detected
            */
            unsupportedBrowserDetected: function() {
            /* PROVIDE FEEDBACK TO SITE VISITORS */
            },

            /*
            * callback function: inputEventReceived
            * Triggered when: visitors interact with SqPaymentForm iframe elements.
            */
            inputEventReceived: function(inputEvent) {
            switch (inputEvent.eventType) {
                case 'focusClassAdded':
                /* HANDLE AS DESIRED */
                break;
                case 'focusClassRemoved':
                /* HANDLE AS DESIRED */
                break;
                case 'errorClassAdded':
                /* HANDLE AS DESIRED */
                break;
                case 'errorClassRemoved':
                /* HANDLE AS DESIRED */
                break;
                case 'cardBrandChanged':
                /* HANDLE AS DESIRED */
                break;
                case 'postalCodeChanged':
                /* HANDLE AS DESIRED */
                break;
            }
            },

            /*
            * callback function: paymentFormLoaded
            * Triggered when: SqPaymentForm is fully loaded
            */
            paymentFormLoaded: function() {
            /* HANDLE AS DESIRED */
            }
        }
        });
    }


    requestCardNonce() {
        // Don't submit the form until SqPaymentForm returns with a nonce
        // event.preventDefault();
        // Request a nonce from the SqPaymentForm object
        this.paymentForm.requestCardNonce();
    }

    onSubmit() {
        //pad data
        if(this.form.value['shippingsameasbilling']){
            this.form.patchValue({
                "firstName1":this.form.get('firstName').value,
                "lastName1":this.form.get('lastName').value,
                "email1":this.form.get('email').value,
                "phone1":this.form.get('phone').value,
                "address1":this.form.get('address').value,
                "city1":this.form.get('city').value,
                "state1":this.form.get('state').value,
                "postal1":this.form.get('postal').value,
                "shippingaddress2":this.form.get('billingaddress2').value,
                "shippingcountrycode":this.form.get('billingcountrycode').value,
                "shippingcountryname":this.form.get('billingcountryname').value,
            });
        }
        // debugger;
        console.log(this.form.value);
        console.log(this.form.valid);

        if(this.form.valid) this.requestCardNonce();
        else{
            // this.requestCardNonce();
            Object.keys(this.form.controls).forEach(field => {  //{2}
                const control = this.form.get(field);
                console.log(control);
                             //{3}
                if (control instanceof FormControl) {             //{4}
                  control.markAsTouched({ onlySelf: true });
                }
              });
        }
    }

    ngOnDestroy() {
    }

    updateOfferStatus() {
        const isClosed = this.offer.isClosed();
        if (isClosed) {
            this.statusHeader = CONSTANTS.OFFER_STATUS.CLOSED;
            this.statusClass = 'closed';
            this.timeRemaining = this.offer.getStatus();

        } else {
            this.statusHeader = CONSTANTS.OFFER_STATUS.OPEN;
            this.statusClass = 'open';
            this.timeRemaining = this.offer.getStatus();
        }
    }

    getCartLink() {
        return `/${CONSTANTS.ROUTES.CART(this.farmSlug)}`;
    }

    getCartStatus() {
        return `Cart (${this.cart.getItemCount()})`;
    }


}
