import { Component, OnInit } from '@angular/core';
import { SessionService } from 'src/app/services/session.service';
import { Farm } from 'src/app/model/farm';
import { ActivatedRoute, Router } from '@angular/router';
import { CONSTANTS } from 'src/app/model/enums';
import { DataStorageService } from 'src/app/services/storage.service';

@Component({
    selector: 'app-order-success',
    templateUrl: './order-success.component.html',
    styleUrls: ['./order-success.component.less']
})
export class OrderSuccessPublicComponent implements OnInit {

    farmSlug: string;
    farmName: string;
    orderId: number;

    constructor(private session: SessionService, private route: ActivatedRoute, private router: Router,private storage: DataStorageService) { }

    ngOnInit() {
        this.session.getFarm().subscribe((farm: Farm) => {
            if (farm) {
                this.farmSlug = farm.slug;
                this.farmName = farm.name;
            }else{
                //get public data from cache
                const slug = window.location.pathname.split('/')[1];
                const farm = this.storage.getFarm(slug);
                this.farmSlug = farm.slug;
                this.farmName = farm.name;
            }
        });

        this.route.paramMap.subscribe((paramMap) => {
            this.orderId = Number(paramMap.get(CONSTANTS.ROUTE_PARAM_KEYS.ORDER_ID));
        });
    }

    viewOrder() {
        this.router.navigate([CONSTANTS.ROUTES.VIEW_ORDER(this.farmSlug, this.orderId)]);
    }

}
