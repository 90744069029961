import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'externalUrl'
})
export class ExternalUrlPipe implements PipeTransform {

    transform(url: string): string {
        if (!url) {
            return '';
        } else if (!(url.startsWith('http://') || url.startsWith('https://'))) {
            return 'https://' + url;
        } else {
            return url;
        }
    }

}
