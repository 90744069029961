import { Component,Inject, OnInit, Input } from '@angular/core';
import { ToastNamedAction } from 'src/app/services/toast.service';
import {MAT_SNACK_BAR_DATA, MatSnackBar} from '@angular/material/snack-bar';
import { LayoutService } from 'src/app/services/layout.service';

@Component({
    selector: 'app-toast',
    templateUrl: './toast.component.html',
    styleUrls: ['./toast.component.less']
})
export class ToastComponent implements OnInit {

    @Input() text: string;
    @Input() actions?: ToastNamedAction[];
    @Input() closeX: boolean;

    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any,public layout: LayoutService, private snackBar: MatSnackBar) {
        this.text = data.text;
        this.actions = data.actions;
        this.closeX = data.closeX;
    }

    ngOnInit() {
    }

    close(){
        this.snackBar.dismiss();
    }

}
