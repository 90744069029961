import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'catalogPlaceholder'
})
export class CatalogPlaceholderPipe implements PipeTransform {

    transform(categoryId: number, size?: 'large' | 'small'): string {
        let filename = 'produce.svg';
        switch (categoryId) {
            case 1:
                filename = 'produce.svg';
                break;
            case 2:
                filename = 'eggs.svg';
                break;
            case 3:
                filename = 'meat.svg';
                break;
            case 4:
                filename = 'other.svg';
                break;
        }

        if (size === 'small') {
            filename = filename.replace('.svg', '-small.svg');
        }

        return filename;
    }

}
