export interface IChangeEmailResponse {
    status: string;
    loginToken: string;
}

export enum EnumChangeEmailStatus {
    SUCCESS = 'SUCCESS',
    INVALID_EMAIL = 'INVALID_EMAIL',
    ALREADY_IN_USE = 'ALREADY_IN_USE',
    BAD_CREDENTIALS = 'BAD_CREDENTIALS',
    BAD_REQUEST = 'BAD_REQUEST'
}
