import { Component, OnInit, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { LayoutService } from 'src/app/services/layout.service';
import { ForgotPasswordComponent, IForgotPasswordDialogParams } from '../forgot-password/forgot-password.component';
import { IModalDialog } from '../../generic/modal/modal.component';
import { SessionService } from 'src/app/services/session.service';
import { Router } from '@angular/router';
import { ILoginResponse, EnumLoginResult } from 'src/app/model/responses/login-response';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.less']
})
export class LoginComponent implements IModalDialog, OnInit {

    labels = {
        lblLogin: 'Login to view Offer Sheet',
        btnLogin: 'Login',

        lnkForgotPassword: 'Forgot your password?',

        placeholderEmail: 'Email',
        placeholderPassword: 'Password'
    };

    farmName = '';
    email: string;
    password: string;
    hasLoginFailed = false;
    loginError = '';

    submitButton: HTMLElement;

    constructor(private dialog: MatDialog, public dialogRef: MatDialogRef<LoginComponent>,
                private layout: LayoutService, private session: SessionService, private element: ElementRef) { }

    ngOnInit() {
        this.submitButton = this.element.nativeElement.getElementsByClassName('btn btn-primary')[0];
        this.session.getFarm().subscribe((farm) => {
            if (farm) {
                this.farmName = farm.name;
            }
        });
    }

    canClose() {
        return false;
    }

    isMinor() {
        return false;
    }

    forgotPassword() {
        // close dialog login when open dialog forgot
        this.dialogRef.close();
        let config: MatDialogConfig<IForgotPasswordDialogParams>;
        config = {
            data: {
                email: this.email,
            }
        };
        Object.assign(config, this.layout.getLoginConfig());
        var dialogForgotPassword = this.dialog.open(ForgotPasswordComponent, this.layout.getModalConfig(config));

        // in the case if open after close => open again dialog login
        dialogForgotPassword.afterOpen().subscribe(() => {
            dialogForgotPassword.afterClosed().subscribe(() => {
                const config: MatDialogConfig = {
                    disableClose: true
                };
                Object.assign(config, this.layout.getLoginConfig());
                this.dialog.open(LoginComponent, this.layout.getModalConfig(config));
            }) 

        }) 
    }

    enterPressed() {
        this.submitButton.click();
    }

    login() {
        this.session.login(this.email, this.password).subscribe((loginResponse: ILoginResponse) => {
            this.dialogRef.close();
        }, (loginResponse: ILoginResponse) => {
            switch (loginResponse.status) {
                case EnumLoginResult.BAD_CREDENTIALS:
                    case EnumLoginResult.USER_NOT_FOUND:
                    this.loginError = 'Sorry, that email and password were not valid. Please try again.';
                    break;
                case EnumLoginResult.OFFER_AUTH_NOT_FOUND:
                    this.loginError = 'Your account is not subscribed to this offer sheet.';
                    break;
                case EnumLoginResult.BAD_REQUEST:
                    this.loginError = 'Unable to log you in. Something went wrong.';
                    break;
            }
            this.hasLoginFailed = true;
        });
    }
}
