import { Component, OnInit, Input } from '@angular/core';
import { SessionService } from '../../../services/session.service';
import { DataStorageService } from 'src/app/services/storage.service';

@Component({
    selector: 'app-currency',
    templateUrl: './currency.component.html',
    styleUrls: ['./currency.component.less']
})
export class CurrencyComponent implements OnInit {

    @Input() value;
    currencyCode: any;


    constructor(public session: SessionService,  private storage: DataStorageService) { }

    ngOnInit() {
      const slug = window.location.pathname.split('/')[1];
      const farm = this.storage.getFarm(slug);
      const code = (farm.currencyCode || this.session.getCurrencyCode()) === 'CAD' ? 'Can$' : (farm.currencyCode || this.session.getCurrencyCode());    
      this.currencyCode = code;
    }
}
