import { Component, OnInit } from '@angular/core';
import { SessionService } from 'src/app/services/session.service';
import { HttpService } from 'src/app/services/http.service';
import { ActivatedRoute } from '@angular/router';
import { CONSTANTS } from 'src/app/model/enums';
import { Order } from 'src/app/model/order';
import { IViewOrderResponse } from 'src/app/model/responses/view-order-response';
import { CatalogItem } from 'src/app/model/catalog-item';
import { LayoutService } from 'src/app/services/layout.service';

@Component({
    selector: 'app-view-order',
    templateUrl: './view-order.component.html',
    styleUrls: ['./view-order.component.less']
})
export class ViewOrderComponent implements OnInit {

    labels = {
        btnBack: 'Back',
        lblDelivery: 'Delivery',
        lblNote: 'Note',
        lblPickupLocation: 'Pickup location',

        colName: 'Name',
        colQuantity: 'Quantity',
        colUnit: 'Unit',
        colPrice: 'Price',

        rowSubtotal: 'Subtotal',
        rowTax: 'Tax',
        rowShipping: 'Shipping',
        rowRefund: 'Refund',
        rowTotal: 'Total'
    };

    order: Order;
    pickupLocationName: string;

    constructor(private route: ActivatedRoute, private session: SessionService, private http: HttpService,
        public layout: LayoutService) { }

    ngOnInit() {
        this.route.paramMap.subscribe((paramMap) => {
            const orderId = paramMap.get(CONSTANTS.ROUTE_PARAM_KEYS.ORDER_ID);
            this.session.getOffer().subscribe((offer) => {
                if (offer) {
                    this.http.get<IViewOrderResponse>('farmlisting/settings/orders/detail', {
                        offersheetId: offer.id.toString(),
                        orderId
                    }).subscribe((response: IViewOrderResponse) => {
                        this.order = new Order(response.order);
                        const catalogItemsMap: { [id: number]: CatalogItem } = {};
                        response.catalogItems.forEach((item) => {
                            catalogItemsMap[item.itemid] = new CatalogItem(item);
                        });
                        // this.order.items.forEach((item) => {
                        //     item.imageUrl = catalogItemsMap[item.catalogItemId].imageUrl;
                        // });
                        this.session.getFarm().subscribe((farm) => {
                            const matchedLocations = farm.pickupLocations.filter((pickupLocation) => {
                                return Number(pickupLocation.id) === this.order.pickupLocationId;
                            });
                            if (matchedLocations.length > 0) {
                                this.pickupLocationName = matchedLocations[0].name;
                            }
                        });
                    });
                }
            });
        });
    }

    goBack() {
        window.history.back();
    }

}
