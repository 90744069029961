import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CartService } from 'src/app/services/cart.service';
import { SessionService } from 'src/app/services/session.service';
import { CONSTANTS } from 'src/app/model/enums';
import { LoginComponent } from '../../login/login/login.component';
import { LayoutService } from 'src/app/services/layout.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastService } from 'src/app/services/toast.service';
import { AddItemComponent } from '../add-item/add-item.component';
import { HttpService } from 'src/app/services/http.service';
import { Offer, OfferItem } from 'src/app/model/offer';
import { Subscription } from 'rxjs';
import { IOfferResponse } from 'src/app/model/responses/offer-response';

@Component({
    selector: 'app-offer',
    templateUrl: './offer.component.html',
    styleUrls: ['./offer.component.less']
})
export class OfferComponent implements OnInit, OnDestroy {

    labels = {
        btnAdd: 'Add',
        lblQuantity: 'Quantity',
        lblSettings: 'Settings',
        lblLogout: 'Log out',
        lblView: 'View',
        lblNotStarted: 'This offer has not started',
        placeholderEnterAmount: 'Enter amount'
    };
    CONSTANTS = CONSTANTS;

    farmSlug: string;
    offersheetId: number;
    offer: Offer;
    deliveryHeader: string;
    statusHeader: string;
    statusClass: string;
    timeRemaining: string;
    message: string;
    items: OfferItem[];
    isClosed = true;
    private isListView = false;

    statusIntervalId: number;
    logoutSubscription: Subscription;
    private loginSubscription: Subscription;
    constructor(public cart: CartService, public session: SessionService, private dialog: MatDialog,
                public layout: LayoutService, private toast: ToastService, private router: Router,
                private route: ActivatedRoute, private http: HttpService) {
    }

    ngOnInit() {
        this.route.paramMap.subscribe((params) => {
            this.farmSlug = params.get(CONSTANTS.ROUTE_PARAM_KEYS.FARM_SLUG);
            this.offersheetId = Number(params.get(CONSTANTS.ROUTE_PARAM_KEYS.OFFER_ID));
            this.session.loadFromCache(this.farmSlug, this.offersheetId);
            this.loadOffer(this.farmSlug, this.offersheetId);
            // this.session.getOffer().subscribe((offer) => {
            //     if (!offer) {
            //         if (this.offersheetId) { this.loadOffer(this.farmSlug, this.offersheetId); }
            //     } else {
            //         this.offer = offer;
            //         this.calculateDisplay();
            //     }
            // });
        });

        this.logoutSubscription = this.session.getLogoutSubject().subscribe(() => {
            if (!this.session.getIsLoggedIn()) {
                this.calculateDisplay();
                this.openLoginPopup();
            }
        });

        this.loginSubscription = this.session.getLoginSubject().subscribe(() => {
            this.calculateDisplay();
        });
    }

    ngOnDestroy() {
        if (this.statusIntervalId) {
            window.clearInterval(this.statusIntervalId);
        }

        this.logoutSubscription.unsubscribe();
        this.loginSubscription.unsubscribe();
    }

    loadOffer(farmSlug: string, offersheetId: number) {
        this.http.get<IOfferResponse>(`farmlisting/offer`, {
            farmSlug,
            offersheetId: offersheetId.toString()
        }).subscribe((response: IOfferResponse) => {
            if (!response) {
                console.error(`No data returned for offerId ${offersheetId}.`);
                this.router.navigate([CONSTANTS.ROUTES.NO_FARM],
                    {queryParams: {farmSlug, offersheetId}});
                return;
            }
            this.offer = new Offer(response.offer);
            this.session.setFarm(response.farm, farmSlug);
            this.session.setOffer(this.offer);
            if (!this.offer.isClosed() && !this.session.getIsLoggedIn()) {
                this.openLoginPopup();
            }
            this.calculateDisplay();
        }, (error) => {
            console.log(error);
            if (error.status === 404) {
                if (error.error.meta.code === '47_16_f') {
                    this.session.setFarm(error.error.data.farm, farmSlug);
                    this.router.navigate([CONSTANTS.ROUTES.NO_OFFER],
                        {queryParams: {farmSlug}});
                } else {
                    this.router.navigate([CONSTANTS.ROUTES.NO_FARM]);
                }
            } else {
                console.error(error);
                this.router.navigate([CONSTANTS.ROUTES.NO_FARM]);
            }
        });
    }

    openLoginPopup() {
        const config: MatDialogConfig = {
            disableClose: true
        };
        Object.assign(config, this.layout.getLoginConfig());
        this.dialog.open(LoginComponent, this.layout.getModalConfig(config));
    }

    calculateDisplay() {
        this.deliveryHeader = `Delivery date ${this.offer.window.delivery.toLocaleDateString([], CONSTANTS.DATE_FORMATS.abbreviated)}`;
        this.updateOfferStatus();
        if (this.statusIntervalId) {
            window.clearInterval(this.statusIntervalId);
        }
        this.statusIntervalId = window.setInterval(() => {
            this.updateOfferStatus();
        }, 5000);
        this.message = this.offer.message ? this.offer.message.replace(/[\n\r\t]/g, '<br/>') : undefined;
        this.items = Object.values(this.offer.items).sort((a: OfferItem, b: OfferItem) => {
            // Sort by name then id ascending
            const nameSort = a.name.localeCompare(b.name);
            if (nameSort === 0) {
                return a.itemDetailId - b.itemDetailId;
            } else {
                return nameSort;
            }
        });
        this.isClosed = this.offer.isClosed();
        this.isListView = this.session.getOfferViewStyleFromCache();
    }

    updateOfferStatus() {
        const isClosed = this.offer.isClosed();
        if (isClosed) {
            this.statusHeader = CONSTANTS.OFFER_STATUS.CLOSED;
            this.statusClass = 'closed';
            this.timeRemaining = this.offer.getStatus();

        } else {
            this.statusHeader = CONSTANTS.OFFER_STATUS.OPEN;
            this.statusClass = 'open';
            this.timeRemaining = this.offer.getStatus();
        }
    }

    getCartLink() {
        return `/${CONSTANTS.ROUTES.CART(this.farmSlug)}`;
    }

    getCartStatus() {
        return `Cart (${this.cart.getItemCount()})`;
    }

    addToCart(item: OfferItem, inputElement?: HTMLInputElement) {
        if (this.layout.isSmall() && !this.getIsListView()) {
            this.dialog.open(AddItemComponent, {
                data: {
                    item,
                    isClosed: this.isClosed
                }
            });
        } else {
            const quantity = Number(inputElement.value);
            if (quantity > 0) {
                inputElement.value = '';
                this.cart.addItem(item, quantity);
                this.toast.open({
                    text: `(${quantity}) ${item.name} added to your cart`,
                    actions: [
                        {
                            label: 'View cart',
                            callback: () => {
                                this.router.navigate([CONSTANTS.ROUTES.CART(this.farmSlug)]);
                            }
                        }
                    ]
                });
            }
        }
    }

    getIsListView(): boolean {
        return this.isListView;
    }

    setIsListView(isListView: boolean): void {
        this.isListView = isListView;
        this.session.saveOfferViewStyleToCache(isListView);
    }

    goToSettings(): void {
        this.router.navigate([CONSTANTS.ROUTES.SETTINGS(this.farmSlug)]);
    }
}
