import { Component, Inject, OnInit, Input } from '@angular/core';
import { IModalDialog } from '../../generic/modal/modal.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CartService } from 'src/app/services/cart.service';
import { ToastService } from 'src/app/services/toast.service';
import { OfferItem } from 'src/app/model/offer';

@Component({
    selector: 'app-add-item',
    templateUrl: './add-item.component.html',
    styleUrls: ['./add-item.component.less']
})
export class AddItemComponent implements IModalDialog {

    labels = {
        lblAddToCart: 'Add to cart',
        lblQuantity: 'Quantity',

        btnAddToCart: 'Add to cart'
    };
    item: OfferItem;
    isClosed: boolean;
    public quantity: any;
    constructor(public dialogRef: MatDialogRef<AddItemComponent>, @Inject(MAT_DIALOG_DATA) data: any,
                public cart: CartService, private toast: ToastService) {

        this.item = {...data.item};
        this.isClosed = data.isClosed;
    }

    canClose() {
        return true;
    }

    isMinor() {
        return false;
    }

    addToCart(inputElement) {
        const quantity = Number(inputElement.value);
        if (quantity > 0) {
            this.cart.addItem(this.item, quantity);
            this.toast.openItemAddedToast(this.item, quantity);
            this.dialogRef.close();
        }
    }
}
