export interface ICatalogItem {
    itemid: number;
    imageurl: string;
}

export class CatalogItem {
    id: number;
    imageUrl: string;

    constructor(item: ICatalogItem) {
        this.id = item.itemid;
        this.imageUrl = item.imageurl;
    }
}
