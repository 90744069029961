import { Directive, ElementRef } from '@angular/core';
import { CONSTANTS } from '../model/enums';

@Directive({
    selector: '[appNumber]'
})
export class NumberDirective {

    constructor(el: ElementRef) {
        const input = el.nativeElement as HTMLInputElement;
        input.onkeydown = (event: KeyboardEvent) => {
            // Prevents entering numeric e or dashes for negative numbers
            if (event.code === CONSTANTS.KEYS.E || event.code === CONSTANTS.KEYS.subtract
                || event.code === CONSTANTS.KEYS.minus) {
                return false;
            }
        };

        input.onkeyup = (event: KeyboardEvent) => {
            if (input.max && Number(input.value) > Number(input.max)) {
                input.value = input.max;
            }
        };
    }

}
