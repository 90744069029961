import { Component, ElementRef, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IModalDialog } from '../../generic/modal/modal.component';
import { ToastService } from 'src/app/services/toast.service';
import { HttpService } from 'src/app/services/http.service';
import { SessionService } from 'src/app/services/session.service';

interface IForgotPasswordRequest {
    email: string;
    farmSlug: string;
}

export interface IForgotPasswordDialogParams {
    email: string;
}

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.less']
})
export class ForgotPasswordComponent implements IModalDialog, OnInit {

    labels = {
        btnSendEmail: 'Send Email',
        btnDismiss: 'Dismiss',

        lblForgotPassword: 'Forgot your password',
        lblEnterEmail: 'Please enter your email',
        lblSubtext: 'If you have a registered email, a reset link will be sent to your email',

        placeholderEmail: 'Email',

        msgEmailSent: 'Your password reset email has been sent',
        msgEmailFailed: 'Failed to send password reset email, please try again'
    };

    email: string;
    farmSlug: string;

    sendButton: HTMLElement;
    constructor(public dialogRef: MatDialogRef<ForgotPasswordComponent>, private toast: ToastService,
        private http: HttpService, private element: ElementRef,
        @Inject(MAT_DIALOG_DATA) private data: IForgotPasswordDialogParams, private session: SessionService) { }

    ngOnInit() {
        this.sendButton = this.element.nativeElement.getElementsByClassName('btnSendEmail')[0];
        this.email = this.data.email;

        this.session.getFarm().subscribe((farm) => {
            if (farm) {
                this.farmSlug = farm.slug;
            }
        });
    }

    canClose() {
        return true;
    }

    isMinor() {
        return false;
    }

    enterPressed() {
        this.sendButton.click();
    }

    sendEmail() {
        this.http.post<IForgotPasswordRequest, boolean>('farmlisting/forgotpassword', {
            email: this.email,
            farmSlug: this.farmSlug
        }).subscribe((success) => {
            this.toast.open({
                text: success ? this.labels.msgEmailSent : this.labels.msgEmailFailed,
                actions: [{
                    label: this.labels.btnDismiss,
                    callback: () => {}
                }]
            });
        }, (error) => {
            console.error(error);
            this.toast.open({
                text: this.labels.msgEmailFailed,
                isError: true,
                actions: [{
                    label: this.labels.btnDismiss,
                    callback: () => {}
                }]
            });
        });
    }
}
