import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { CartService } from 'src/app/services/cart.service';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { LayoutService } from 'src/app/services/layout.service';
import { HttpService } from 'src/app/services/http.service';
import { SessionService } from 'src/app/services/session.service';
import { ToastService } from 'src/app/services/toast.service';
import { Farm } from 'src/app/model/farm';
import { Offer } from 'src/app/model/offer';
import { CONSTANTS } from 'src/app/model/enums';
import { DataStorageService } from 'src/app/services/storage.service';
import { PaymentService } from 'src/app/services/payment.service';
import { StoreService } from 'src/app/services/store.service';
import { IOfferFullResponse } from 'src/app/model/responses/offer-response';
import { HttpErrorResponse } from '@angular/common/http';
import { GTMService } from 'src/app/services/GTM.service';

@Component({
  selector: 'app-cart-public',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.less']
})
export class CartPublicComponent implements OnInit, OnDestroy {

  labels = {
    colName: 'Name',
    colQuantity: 'Quantity',
    colUnits: 'Units',
    colPrice: 'Price',

    lblShoppingCart: 'Shopping Cart',
    lblPlaceholderNotes: 'Leave a comment',
    lblNotes: 'Notes',
    lblSubtotal: 'Subtotal',
    lblTax: 'Tax',
    lblShipping: 'Delivery',
    lblTotal: 'Total',
    lblCartEmpty: 'Your shopping cart is empty!',
    lblCartEmptyDesc: 'Optional copy text if we want to add something about adding to the cart or anything we think.',
    lblInvoice: 'You will be invoiced for your order',
    lblNotAvailable: 'Not available',
    lblPickupLocation: 'Pick up location',
    lblSelectLocation: 'Select a pickup location',
    lblFree: 'FREE',

    btnSendOrder: 'Continue'
  };

  cartEntries: any = [];
  cartForm: FormGroup;
  pickupLocationFormControl: FormControl;
  note;
  pickupLocationId: any;
  subtotal;
  tax = 0;
  shipping = 0;
  total;
  farm: Farm;
  offer: Offer;
  farmSlug: any;
  orderid: string;
  currTax: any;
  currShipping: any;
  currInstruction: any;

  navStart: Observable<NavigationStart>;
  navStartSubscription: Subscription;
  farmSubscription: Subscription;
  offerSubscription: Subscription;
  constructor(private cart: CartService, private formBuilder: FormBuilder, private router: Router,
    public layout: LayoutService, public session: SessionService, private storage: DataStorageService,
    private http: HttpService, private toast: ToastService, public route: ActivatedRoute,
    private paymentService: PaymentService,private gtm:GTMService) {
  }

  ngOnInit() {
    this.navStart = this.router.events.pipe(filter(event => event instanceof NavigationStart)) as Observable<NavigationStart>;
    this.navStartSubscription = this.navStart.subscribe(() => {
      this.cart.removeEmpties();
    });

    this.cartForm = this.formBuilder.group({
      quantities: '',
      pickupLocation: ''
    });

    const farm = this.storage.getFarm(this.farmSlug) || [];

    this.farm = farm;
    this.farm.pickupLocations = [];
    this.route.paramMap.subscribe((params) => {
      this.farmSlug = params.get(CONSTANTS.ROUTE_PARAM_KEYS.FARM_SLUG);
      this.cartEntries = this.storage.getCartList(this.farmSlug).sort((a,b) => a.item.name.localeCompare(b.item.name)) || [];
      this.note = (this.storage.getNote(this.farmSlug) || {})['note'];

      this.cartEntries.forEach((item, index) => {
        item['checkedSoldout'] = false;
        if (item.quantity > item.item.available) {
          item.quantity = item.item.available;
        }
      });

      this.loadPickUpLocations(this.farmSlug);

      this.gtm.trackingCheckout(this.cartEntries,this.farm,1,'Cart');
    });
  }

  ngOnDestroy() {
    // this.navStartSubscription.unsubscribe();
    // this.farmSubscription.unsubscribe();
    // this.offerSubscription.unsubscribe();
  }

  getCartStatus() {
    return `Cart (${this.cartEntries.length})`;
  }

  changeNote() {
    this.storage.setNote({note: this.note}, this.farmSlug);
  }

  changeQuantity(item, i) {
    this.calculateTotal();
    this.storage.setCartList(this.cartEntries, this.farmSlug);
  }

  changePickupLocation(event?) {
    const value = event ? event.id : this.pickupLocationFormControl;
    const index = this.farm.pickupLocations.findIndex(x => x.id === value);
    this.storage.setpickupId(this.farm.pickupLocations[index], this.farmSlug);

    this.currTax = this.farm.pickupLocations[index]['tax'];
    this.currShipping = this.farm.pickupLocations[index]['price'];
    var numberOfLineBreaks = this.farm.pickupLocations[index]['isinstruction'] ? this.farm.pickupLocations[index]['instruction']['content'] : '';
    this.currInstruction = numberOfLineBreaks.replace(/\r?\n/g, "<br />");

    this.pickupLocationId = value;
    this.calculateTotal();
  }

  deleteCartEntry(entry, index) {
    this.gtm.trackingRemoveCart(this.cartEntries[index]['item'],this.farm);
    this.cartEntries.splice(index, 1);
    this.calculateTotal();
    this.storage.setCartList(this.cartEntries, this.farmSlug);
  }

  getSubtotal() {
    const total = this.cartEntries.reduce((tempTotal, cartEntry, index, items) => {
      return tempTotal + (cartEntry.quantity * cartEntry.item.price);
    }, 0);
    return total;
  }

  private calculateTotal() {
    this.subtotal = this.getSubtotal();
    this.tax = Number(this.currTax) / 100 * Number(this.subtotal);
    this.shipping = this.currShipping;
    this.total = this.subtotal + this.tax + this.shipping;
    const data = {
      subtotal: this.subtotal,
      total: this.total,
      tax: this.tax,
      shipping: this.shipping,
      orderid: this.orderid
    }
    this.storage.setTotal(this.farmSlug, data);
  }


  payment() {
    var that = this;
    const data = {
      pickuplocationid: this.pickupLocationId,
      paymentamount: this.total,
      subtotalprice: this.subtotal,
      tax: this.tax,
      shipping: this.shipping,
      farmid: this.farm.id,
      orderid: this.orderid,
      note: this.note,
      itemdetail: [
      ]
    };
    this.cartEntries.forEach((cart, index) => {
      var item = cart.item;
      data.itemdetail.push({
        itemid: item.id,
        itemdetailid: item.itemDetailId,
        packunitname: item.unit.name,
        subtotalprice: (cart.quantity * item.price),
        itemname: item.name,
        quantity: cart.quantity,
        price: item.price
      });
    });
    //validation for total too large
    if(Number(this.total) >= 50000){
      let windowClosedConfig = {
        text: `Your total cannot be more than 50,000`,
        actions: [
          {
            label: 'Dismiss',
            callback: () => {
            }
          }
        ],
        isError: true,
        closeX: true
      };
      that.toast.open(windowClosedConfig);
      return;
    }

    this.storage.setOrder(data,this.farmSlug);

    this.paymentService.checkSoldOut(data).subscribe((response) => {
      this.router.navigate([CONSTANTS.ROUTES.PUBLIC_CHECK_OUT(this.farmSlug)]);
    }, (error) => {
      if (error.body.data.itemSoldouts.length > 0) {
        error.body.data.itemSoldouts.forEach((it, index) => {
          this.cartEntries.forEach((x, index) => {
            if (!it.resultLimitOrder) {
              if (it.itemdetailid === x.item['itemDetailId']) {
                x['checkedSoldout'] = true;
              }
            }
          });
          if (!it.resultLimitOrder) {
            const index = this.cartEntries.findIndex(x => x.item['itemDetailId'] === it.itemdetailid);
            this.cartEntries[index]['checkedSoldout'] = true;
          }
        });

      }


      if (error.body.meta.code === '45_23_f') {
        let windowClosedConfig = {
          text: `There were item(s) in your cart that are no longer available. Please remove them and resubmit your order`,
          actions: [
            {
              label: 'Dismiss',
              callback: () => {
              }
            }
          ],
          isError: true,
          closeX: true
        };
        that.toast.open(windowClosedConfig);
      }
    });

  }

  loadPickUpLocations(farmSlug: string) {
    this.http.get<any>(CONSTANTS.API_ENDPOINT.GET_PICKUP_LOCATIONS, {
      farmSlug
    }).subscribe((response: any) => {
      const farm = this.storage.getFarm(this.farmSlug) || [];
      farm.pickupLocations = response || [];
      this.farm = farm;
      // this.calculateTotal();
      var cartInfo = this.storage.getTotal(this.farmSlug) || {};
      this.orderid = cartInfo.orderid || null;

      this.pickupLocationFormControl = (this.storage.getpickupId(this.farmSlug) || {})['id'];

      if (farm.pickupLocations.length > 0 && !this.pickupLocationFormControl) {
        this.pickupLocationFormControl = farm.pickupLocations[0].id;
      }
      this.changePickupLocation();

    }, (error: HttpErrorResponse) => {
      if (error.status === 404) {
        if (error.error.meta.code === '47_16_f') {
          this.session.setFarm(error.error.data.farm, farmSlug);
          this.router.navigate([CONSTANTS.ROUTES.NO_OFFER]);
        } else {
          this.router.navigate([CONSTANTS.ROUTES.NO_FARM]);
        }
      } else {
        console.error(error);
        this.router.navigate([CONSTANTS.ROUTES.NO_FARM]);
      }
    });
  }
}
