export interface IChangePasswordResponse {
    status: string;
    loginToken: EnumChangePasswordStatus;
}

export enum EnumChangePasswordStatus {
    SUCCESS = 'SUCCESS',
    BAD_REQUEST = 'BAD_REQUEST',
    BAD_CREDENTIALS = 'BAD_CREDENTIALS'
}
