import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpService } from '../services/http.service';
import { Farm } from '../model/farm';
import { SessionService } from '../services/session.service';
import { CONSTANTS } from '../model/enums';

@Injectable({
    providedIn: 'root'
})
export class ResetPasswordGuard implements CanActivate {

    constructor(private http: HttpService, private session: SessionService, private router: Router) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (!next.queryParams.email || !next.queryParams.token || !next.queryParams.slug) {
            return false;
        }

        const farmSlug = next.queryParams.slug;

        return new Observable<boolean>((observer) => {
            this.http.get<Farm>('farmlisting/resetpassword/branding', {
                farmSlug
            }).subscribe((farm) => {
                this.session.setFarm(farm, farmSlug);
                observer.next(true);
            }, (error) => {
                console.log(error);
                this.router.navigate([CONSTANTS.ROUTES.NO_FARM]);
                observer.next(false);
            });
        });
    }
}
