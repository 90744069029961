import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HtmlPipe } from './pipes/html.pipe';

@NgModule({
  declarations: [HtmlPipe],
  exports: [HtmlPipe],
  imports: [
    CommonModule
  ]
})
export class SharedModule { }
