import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { CONSTANTS } from '../model/enums';
import { Injectable } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';

@Injectable({
    providedIn: 'root'
})
export class LayoutService {

    layoutClass = CONSTANTS.LAYOUT_CLASSES.LARGE;

    constructor(breakpointObserver: BreakpointObserver) {
        breakpointObserver.observe([
            Breakpoints.Small,
            Breakpoints.Medium,
            Breakpoints.Large,
            Breakpoints.XLarge
        ]).subscribe(result => {
            
            if (result.breakpoints[Breakpoints.XLarge]) {
                this.layoutClass = CONSTANTS.LAYOUT_CLASSES.LARGE;
            } else if (result.breakpoints[Breakpoints.Large]) {
                this.layoutClass = CONSTANTS.LAYOUT_CLASSES.LARGE;
            } else if (result.breakpoints[Breakpoints.Medium]) {
                this.layoutClass = CONSTANTS.LAYOUT_CLASSES.MEDIUM;
            } else {
                this.layoutClass = CONSTANTS.LAYOUT_CLASSES.SMALL;
            }
        });
    }

    isLarge() {
        return this.layoutClass === CONSTANTS.LAYOUT_CLASSES.LARGE;
    }

    isMedium() {
        return this.layoutClass === CONSTANTS.LAYOUT_CLASSES.MEDIUM;
    }

    isSmall() {
        return this.layoutClass === CONSTANTS.LAYOUT_CLASSES.SMALL;
    }

    getLayoutClass() {
        return this.customLayoutClass() ? this.customLayoutClass() : this.layoutClass;
    }

    customLayoutClass() {
        if (window.location.pathname.indexOf('reset-password') !== -1) {
            return 'fl-layout-custom-w-500';
        }

        return null;
    }

    getModalConfig(options?: MatDialogConfig): MatDialogConfig {
        const config = {};
        const sizing = this.isSmall() ?
            {
                width: '300px'
            } :
            {
                width: '500px'
            };
        Object.assign(config, sizing);
        Object.assign(config, options);
        return config;
    }

    getLoginConfig(): MatDialogConfig {
        return {
            autoFocus: false,
            width: this.isSmall() ? '300px' : '500px',
            height: this.isSmall() ? '500px' : 'auto'
        };
    }
}
