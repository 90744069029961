import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { CONSTANTS } from '../model/enums';
import { TendResponse } from '../model/tend-response';

@Injectable({
    providedIn: 'root'
})
export class HttpService {

    loadingSubject: BehaviorSubject<boolean>;

    constructor(private http: HttpClient) {
        this.loadingSubject = new BehaviorSubject<boolean>(false);
    }

    public getIsLoading(): BehaviorSubject<boolean> {
        return this.loadingSubject;
    }

    get<T>(route: string, params?: HttpParams | {
        [param: string]: string | string[];
    }): Observable<T> {
        this.loadingSubject.next(true);
        return new Observable<T>((observer) => {
            this.http.get<TendResponse<T>>(CONSTANTS.API_ROOT + route, {
                observe: 'response',
                params
            }).subscribe((response) => {
                observer.next(response.body.data);
                observer.complete();
            }, (error) => {
                observer.error(error);
            }).add(() => {
                this.loadingSubject.next(false);
            });
        });
    }

    // service get full data from response server, include: meta and data
    getfull<T>(route: string, params?: HttpParams | {
        [param: string]: string | string[];
    }): Observable<T> {
        this.loadingSubject.next(true);
        return new Observable<T>((observer) => {
            this.http.get<T>(CONSTANTS.API_ROOT + route, {
                observe: 'response',
                params
            }).subscribe((response) => {
                observer.next(response.body);
                observer.complete();
            }, (error) => {
                observer.error(error);
            }).add(() => {
                this.loadingSubject.next(false);
            });
        });
    }

    
    post<T, V>(route: string, data: T): Observable<V> {
        this.loadingSubject.next(true);
        return new Observable<V>((observer) => {
            this.http.post<TendResponse<V>>(CONSTANTS.API_ROOT + route, data, {
                observe: 'response'
            }).subscribe((response) => {
                if (response.body.meta.code.endsWith('_f')) {
                    observer.error(response);
                } else {
                    observer.next(response.body.data);
                }
                observer.complete();
            }, (error) => {
                observer.error(error.error.data);
            }).add(() => {
                this.loadingSubject.next(false);
            });
        });
    }
}
