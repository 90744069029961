import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormArray, FormControl } from '@angular/forms';
import { CartService } from 'src/app/services/cart.service';
import { CartEntry } from 'src/app/model/cart-entry';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { LayoutService } from 'src/app/services/layout.service';
import { HttpService } from 'src/app/services/http.service';
import { ISubmitOrderRequest } from 'src/app/model/requests/submit-order-request';
import { SessionService } from 'src/app/services/session.service';
import { ToastService, ToastConfig } from 'src/app/services/toast.service';
import { Farm } from 'src/app/model/farm';
import * as moment from 'moment-timezone';
import { OfferWindow, Offer } from 'src/app/model/offer';
import { CONSTANTS } from 'src/app/model/enums';
import { EnumCartSubmitStatus, ICartResponse } from 'src/app/model/responses/cart-response';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'app-cart',
    templateUrl: './cart.component.html',
    styleUrls: ['./cart.component.less']
})
export class CartComponent implements OnInit, OnDestroy {

    labels = {
        colName: 'Name',
        colQuantity: 'Quantity',
        colUnits: 'Units',
        colPrice: 'Price',

        lblShoppingCart: 'Shopping Cart',
        lblPlaceholderNotes: 'Leave a comment',
        lblNotes: 'Notes',
        lblSubtotal: 'Subtotal',
        lblTax: 'Tax',
        lblShipping: 'Shipping',
        lblTotal: 'Total',
        lblCartEmpty: 'Your cart is empty!',
        lblInvoice: 'You will be invoiced for your order',
        lblNotAvailable: 'Not available',
        lblPickupLocation: 'Pickup location',
        lblSelectLocation: 'Select a pickup location',
        lblFree: 'FREE',

        btnSendOrder: 'Send Order'
    };

    cartEntries: CartEntry[];
    cartForm: FormGroup;
    pickupLocationFormControl: FormControl;
    note;
    pickupLocationId: number;
    subtotal;
    tax;
    shipping;
    total;
    farm: Farm;
    offer: Offer;
    farmSlug: any;

    navStart: Observable<NavigationStart>;
    navStartSubscription: Subscription;
    farmSubscription: Subscription;
    offerSubscription: Subscription;
    constructor(private cart: CartService, private formBuilder: FormBuilder, private router: Router,
        public layout: LayoutService, public session: SessionService,
        private http: HttpService, private toast: ToastService, public route: ActivatedRoute) {
    }

    ngOnInit() {
        if (!this.session.getIsLoggedIn()) {
            this.labels.btnSendOrder = 'Continue'
        }
        this.navStart = this.router.events.pipe(filter(event => event instanceof NavigationStart)) as Observable<NavigationStart>;
        this.navStartSubscription = this.navStart.subscribe(() => {
            this.cart.removeEmpties();
        });

        this.offerSubscription = this.session.getOffer().subscribe((offer) => {
            this.offer = offer;
        });

        this.route.paramMap.subscribe((params) => {
            this.farmSlug = params.get(CONSTANTS.ROUTE_PARAM_KEYS.FARM_SLUG);
        });

        this.farmSubscription = this.session.getFarm().subscribe((farm: Farm) => {
            if (farm) {
                this.farm = farm;
                this.initPickupLocation();
                this.initQuantityInputs();
                this.calculateTotal();
            }
        });
    }

    ngOnDestroy() {
        this.navStartSubscription.unsubscribe();
        this.farmSubscription.unsubscribe();
        this.offerSubscription.unsubscribe();
    }

    private initQuantityInputs() {
        this.cartEntries = this.cart.getEntries();
        this.cartEntries.forEach((item, index) => {
          if(item.quantity > item.item.limit) {
            item.quantity = item.item.limit;
          }
        });
        const quantityFormControls = new Array(this.cartEntries.length);
        this.cartEntries.forEach((entry: CartEntry, index) => {
            quantityFormControls[index] = this.formBuilder.control({
                value: entry.quantity,
                disabled: entry.getIsDisabled()
            }, [Validators.min(0)]);
        });
        const quantityFormArray = this.formBuilder.array(quantityFormControls);
        quantityFormArray.valueChanges.subscribe(this.onQuantityChanged);
        this.cartForm = this.formBuilder.group({
            quantities: quantityFormArray,
            pickupLocation: this.pickupLocationFormControl
        });
    }

    private initPickupLocation() {
        // this.pickupLocationId = this.cart.getPickupLocationId();
        // if (this.farm.pickupLocations.length === 1) {
        //     this.pickupLocationId = this.farm.pickupLocations[0].id;
        // }
        this.pickupLocationFormControl = new FormControl('', Validators.required);
        this.pickupLocationFormControl.valueChanges.subscribe(this.onPickupLocationChanged);
        // this.pickupLocationFormControl.setValue(this.farm.pickupLocations[0].id);
        // this.calculateTotal();
        this.loadPickUpLocations(this.farmSlug);
    }

    onQuantityChanged = (values: number[]) => {
        values.forEach((quantity, index) => {
            const entry = this.cartEntries[index];
            this.cart.updateItem(entry.item.id, quantity);
        });
        this.calculateTotal();
    }

    onPickupLocationChanged = (value: number) => {
        this.cart.setPickupLocationId(value);
        this.calculateTotal();
    }

    deleteCartEntry = (entry: CartEntry, index: number) => {
        this.cartEntries.splice(index, 1);
        this.cart.removeItem(entry.item.id);
        this.calculateTotal();
    }

    private calculateTotal() {
        this.subtotal = this.cart.getSubtotal(this.cartEntries);
        const pickupLocation = this.farm.pickupLocations.find(location => location.id === this.pickupLocationId);
        if (pickupLocation) {
            this.shipping = pickupLocation.price;
            this.tax = Number(pickupLocation['tax'])/100*Number(this.subtotal);
        } else {
            this.shipping = 0;

            if (this.pickupLocationFormControl) {

              this.shipping = this.farm.pickupLocations[0]['price'];
              this.tax = Number(this.farm.pickupLocations[0]['tax'])/100*Number(this.subtotal);

            }
        }
        this.total = this.subtotal + this.tax + this.shipping;
    }

    /**
     * Sends the order or generates errors for the user depending on whether the cart is valid.
     * The cart could be invalid for any of the following reasons:
     *  - The offer expired
     *  - The user is no longer subscribed to the offer
     *  - Any item was removed from the catalog or hidden from the offer
     *  - The quantity ordered for any item exceeded its limit, or the item was sold out
     */
    sendOrder() {
        if (!this.getIsCartValid()) {
            this.toast.open({
                text: 'Missing required information. Cannot send the order.',
                isError: true,
                actions: [{
                    label: 'Dismiss',
                    callback: () => { }
                }]
            });
            return;
        }

        const order: ISubmitOrderRequest = {
            offerId: this.offer.id,
            accountId: this.session.getAccount().buyerAccountId,
            note: this.note,
            window: this.offer.window,
            quantities: undefined,
            pickupLocationId: this.pickupLocationId || this.pickupLocationFormControl.value,
            taxcost: this.tax
        };
        this.assembleItemsPayload(order);

        const dateFormat = 'MMM D YYYY h:mm a zz';
        const timezone = this.farm.timeZoneId;
        let newOffer: Offer;
        let windowClosedConfig: ToastConfig = {
            text: `Your order could not be placed.
                Orders closed for ${this.farm.name} at ${moment(this.offer.window.close).tz(timezone).format(dateFormat)}.
                The current time is ${moment(new Date()).tz(timezone).format(dateFormat)}.`,
            actions: [
                {
                    label: 'Dismiss',
                    callback: () => { }
                }
            ],
            isError: true
        };

        const internalServerErrorConfig: ToastConfig = {
            text: `Something went wrong. Your order could not be placed.`,
            isError: true,
            actions: [
                {
                    label: 'Dismiss',
                    callback: () => { }
                }
            ]
        };

        const missingItemsErrorConfig: ToastConfig = {
            text: `There were items in your cart that are no longer available. Please remove them and resubmit your order.`,
            actions: [
                {
                    label: 'Dismiss',
                    callback: () => {

                    }
                }
            ],
            isError: true,
            duration: 5000
        };

        const soldOutItemsErrorConfig: ToastConfig = {
            text: `Your order could not be placed. Some items may have sold out.
                Please remove or adjust unavailable items and try again.`,
            actions: [
                {
                    label: 'Dismiss',
                    callback: () => {

                    }
                }
            ],
            isError: true,
            duration: 5000
        };

        if (this.offer.isClosed()) {
            this.toast.open(windowClosedConfig);
            this.cart.clear();
            this.session.killCache();
        } else {
            this.http.post<ISubmitOrderRequest, ICartResponse>('farmlisting/offer/sendOrder', order).subscribe((response) => {
                this.deductQuantitiesFromOffer();
                this.cart.clear();
                this.session.killCartCache();
                this.router.navigate([CONSTANTS.ROUTES.CART_SUCCESS(this.farm.slug, response.orderId)]);
            }, (error: ICartResponse) => {
                console.error(error);
                const status: EnumCartSubmitStatus = error ? error.status : undefined;
                switch (status) {
                    case EnumCartSubmitStatus.OFFER_EXPIRED.valueOf():
                        this.cart.clear();
                        this.session.killCache();
                        const lastWindow: OfferWindow = new OfferWindow(error.lastWindow);
                        windowClosedConfig = {
                            text: `Your order could not be placed.
                                Orders closed for ${this.farm.name} at ${moment(lastWindow.close).tz(timezone).format(dateFormat)}.
                                The current time is ${moment(new Date()).tz(timezone).format(dateFormat)}.`,
                            actions: [
                                {
                                    label: 'Dismiss',
                                    callback: () => {
                                    }
                                }
                            ],
                            isError: true
                        };
                        this.toast.open(windowClosedConfig);
                        break;
                    case EnumCartSubmitStatus.USER_NOT_FOUND.valueOf():
                        this.cart.clear();
                        this.session.killCache();
                        this.session.logout();
                        const accountUnsubscribedConfig = {
                            text: `Your order could not be placed.
                                Your account was unsubscribed from this offer.`,
                            actions: [
                                {
                                    label: 'Dismiss',
                                    callback: () => {
                                    }
                                }
                            ],
                            isError: true
                        };
                        this.toast.open(accountUnsubscribedConfig);
                        break;
                    case EnumCartSubmitStatus.ITEM_NOT_AVAILABLE.valueOf():
                        newOffer = new Offer(error.newOffer);
                        this.session.setOffer(newOffer);
                        this.mapInvalidItems(newOffer);
                        this.toast.open(missingItemsErrorConfig);
                        break;
                    case EnumCartSubmitStatus.ITEM_SOLD_OUT.valueOf():
                        newOffer = new Offer(error.newOffer);
                        this.session.setOffer(newOffer);
                        this.mapInvalidItems(newOffer);
                        this.toast.open(soldOutItemsErrorConfig);
                        break;
                    default:
                        this.toast.open(internalServerErrorConfig);
                        break;
                }
            });
        }
    }

    /**
     * Returns true if all fields in the cart form are valid (currently only needs to check pickup location).
     */
    private getIsCartValid(): boolean {
        this.pickupLocationFormControl.markAsDirty();
        this.pickupLocationFormControl.markAsTouched();
        return this.pickupLocationFormControl.valid;
    }

    /**
     * Maps from cart entries to the quantities dictionary expected by the order creation service.
     * @param order The order we are about to submit
     */
    private assembleItemsPayload(order: ISubmitOrderRequest) {
        order.quantities = {};
        this.cartEntries.forEach((entry: CartEntry) => {
            order.quantities[entry.item.itemDetailId] = entry.quantity;
        });
    }

    /**
     * Updates valid state of cart items. Items may no longer exist in the offer, or may be oversold or sold out.
     * @param offer The reloaded offer fresh from the database
     */
    private mapInvalidItems(offer: Offer) {
        const quantities: FormArray = this.cartForm.controls.quantities as FormArray;
        const controls = quantities.controls;
        for (let index = 0; index < this.cartEntries.length; index++) {
            const entry: CartEntry = this.cartEntries[index];
            const serverItem = offer.items[entry.item.id];
            if (serverItem) {
                // Item still exists in the offer
                if (serverItem.getAvailable() <= 0) {
                    entry.isServerSoldOut = true;
                } else {
                    entry.item.limit = serverItem.limit;
                    entry.item.sold = serverItem.sold + entry.quantity;
                    entry.item.serverSold = serverItem.serverSold;
                }
                entry.exists = true;
            } else {
                // Item no longer exists on the database (deleted from catalog or hidden from offer)
                entry.exists = false;
            }

            if (entry.getIsDisabled()) {
                controls[index].disable();
            } else {
                controls[index].enable();
            }
        }
    }

    /**
     * Upon successful order, deduct the quantities ordered from what's listed as available in the local copy of the offer.
     */
    deductQuantitiesFromOffer() {
        this.cartEntries.forEach((entry) => {
            const cartItem = entry.item;
            const offerItem = this.offer.items[cartItem.id];
            offerItem.sold += entry.quantity;
        });
    }

    loadPickUpLocations(farmSlug: string): any {
        this.http.get<any>(CONSTANTS.API_ENDPOINT.GET_PICKUP_LOCATIONS, {
            farmSlug
        }).subscribe((response: any) => {
            this.farm.pickupLocations = response || [];
            this.pickupLocationId = this.cart.getPickupLocationId();
            if (this.farm.pickupLocations.length > 0) {
                this.pickupLocationId = this.farm.pickupLocations[0].id;
            }
            this.pickupLocationFormControl.setValue(this.farm.pickupLocations[0].id);
            this.calculateTotal();

        }, (error: HttpErrorResponse) => {
            if (error.status === 404) {
                if (error.error.meta.code === '47_16_f') {
                    this.session.setFarm(error.error.data.farm, farmSlug);
                    this.router.navigate([CONSTANTS.ROUTES.NO_OFFER]);
                } else {
                    this.router.navigate([CONSTANTS.ROUTES.NO_FARM]);
                }
            } else {
                console.error(error);
                this.router.navigate([CONSTANTS.ROUTES.NO_FARM]);
            }
        });
    }
}
