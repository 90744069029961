import { Component, OnInit } from '@angular/core';
import { LayoutService } from 'src/app/services/layout.service';
import { SessionService } from 'src/app/services/session.service';
import { Farm } from 'src/app/model/farm';
import { ActivatedRoute, Router } from '@angular/router';
import { CONSTANTS } from 'src/app/model/enums';
import { HttpService } from 'src/app/services/http.service';
import { IOfferResponse, IOfferFullResponse } from 'src/app/model/responses/offer-response';
import { HttpErrorResponse } from '@angular/common/http';
import { DataStorageService } from 'src/app/services/storage.service';
import { StoreService } from 'src/app/services/store.service';

@Component({
    selector: 'app-about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.less']
})
export class AboutComponent implements OnInit {

    labels = {
        lblHeader: 'About us',
        lblFindUs: 'Find us on the web'
    };

    farmDescription: string;
    imageUrls: string[];

    facebookLink: string;
    instagramLink: string;
    twitterLink: string;
    youtubeLink: string;

    facebookIcon = 'fb.svg';
    instagramIcon = 'instagram_logo.svg';
    twitterIcon = 'twitter.svg';
    youtubeIcon = 'youtube.svg';

    constructor(public layout: LayoutService, private session: SessionService, private route: ActivatedRoute,
                private http: HttpService, private router: Router, private storage: DataStorageService, private storeService: StoreService) { }

    ngOnInit() {
        // this.session.getFarm().subscribe((farm: Farm) => {
        //     if (farm) {
        //         this.initData(farm);
        //     } else {
        this.route.paramMap.subscribe((params) => {
            const farmSlug = params.get(CONSTANTS.ROUTE_PARAM_KEYS.FARM_SLUG);
            this.loadFarm(farmSlug);
        });
        //     }
        // });
    }

    // initData(farm: Farm) {
    //     this.farmDescription = farm.about;
    //     this.imageUrls = farm.storyImageUrls;
    //     this.facebookLink = farm.facebook;
    //     this.instagramLink = farm.instagram;
    //     this.twitterLink = farm.twitter;
    //     this.youtubeLink = farm.youtube;
    // }

    loadFarm(farmSlug: string) {
        const params = { ...{farmSlug: farmSlug}};
        this.storeService.getPublicOffer(params).subscribe((response: IOfferFullResponse) => {
          console.log(response);

          if (!response.data || !response.data.offer || response.data.offer == null) {
            if (response.meta.code === '47_16_f') {
              // return to no offer page
              this.router.navigate([CONSTANTS.ROUTES.NO_OFFER],
                {queryParams: {farmSlug}});
            } else if (response.meta.code === '48_1_f') {
              // return to no farm page
              this.router.navigate([CONSTANTS.ROUTES.NO_FARM]);
            }
            return;
          }

            this.session.setFarm(response.data.farm, farmSlug);
            this.storage.setFarm(response.data.farm, farmSlug);
            const farm = this.storage.getFarm(farmSlug);
            this.farmDescription = farm.about;
            this.imageUrls = farm.storyImageUrls;
            this.facebookLink = farm.facebook;
            this.instagramLink = farm.instagram;
            this.twitterLink = farm.twitter;
            this.youtubeLink = farm.youtube;

        }, (error: HttpErrorResponse) => {
            console.error(error);
            this.router.navigate([CONSTANTS.ROUTES.NO_FARM]);
        });
    }

}
