import { IAccount } from '../account';

export interface ILoginResponse {
    success: boolean;
    account: IAccount;
    status: EnumLoginResult;
}

export enum EnumLoginResult {
    BAD_REQUEST = 'BAD_REQUEST',
    USER_NOT_FOUND = 'USER_NOT_FOUND',
    BAD_CREDENTIALS = 'BAD_CREDENTIALS',
    OFFER_AUTH_NOT_FOUND = 'OFFER_AUTH_NOT_FOUND',
    SUCCESS = 'SUCCESS'
}
