import { IOfferWindow, IOffer } from '../offer';

export interface ICartResponse {
    orderId: number;
    newOffer: IOffer;
    lastWindow: IOfferWindow;
    status: EnumCartSubmitStatus;
}

export enum EnumCartSubmitStatus {
    SUCCESS = 'SUCCESS',
    OFFER_EXPIRED = 'OFFER_EXPIRED',
    USER_NOT_FOUND = 'USER_NOT_FOUND',
    ITEM_SOLD_OUT = 'ITEM_SOLD_OUT',
    ITEM_NOT_AVAILABLE = 'ITEM_NOT_AVAILABLE',
    BAD_REQUEST = 'BAD_REQUEST'
}
