import { Component, OnInit } from '@angular/core';
import { ToastService } from 'src/app/services/toast.service';
import { HttpService } from 'src/app/services/http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { IResetPasswordRequest } from 'src/app/model/requests/reset-password-request';
import { IResetPasswordResponse } from 'src/app/model/responses/reset-password-response';
import { CONSTANTS } from 'src/app/model/enums';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.less'],
})
export class ResetPasswordComponent implements OnInit {

    labels = {
        lblChangePassword: 'Change Password',
        lblNew: 'New password',
        lblConfirmNew: 'Confirm new password',
        lblResetSuccess: 'Your password has been reset',
        lblDismiss: 'Dismiss',
        lblFailed: 'Failed to reset your password. Your token may have expired.',

        placeholderNewPassword: 'Password must be at least 8 characters',

        btnUpdate: 'Update'
    };

    email: string;
    token: string;
    newPassword: string;
    confirmNewPassword: string;
    farmSlug: string;

    constructor(private toast: ToastService, private http: HttpService, private route: ActivatedRoute, private router: Router) { }

    ngOnInit() {
        this.route.queryParamMap.subscribe((params) => {
            this.email = params.get('email');
            this.token = params.get('token');
            this.farmSlug = params.get('slug');
        });
    }

    savePassword() {
        this.http.post<IResetPasswordRequest, IResetPasswordResponse>('farmlisting/resetpassword', {
            email: this.email,
            token: this.token,
            newPassword: btoa(this.newPassword),
            farmSlug: this.farmSlug
        }).subscribe(() => {
            this.toast.open({
                text: this.labels.lblResetSuccess,
                actions: [
                    {
                        label: this.labels.lblDismiss,
                        callback: () => {}
                    }
                ]
            });

            // redirect to about page after reset pass word
            this.router.navigate([CONSTANTS.ROUTES.ABOUT(this.farmSlug)]);
        }, (error) => {
            console.error(error);
            this.toast.open({
                text: this.labels.lblFailed,
                isError: true,
                actions: [{
                    label: this.labels.lblDismiss,
                    callback: () => {}
                }]
            });
        });
    }
}
