import { Directive, ElementRef } from '@angular/core';

@Directive({
    selector: '[appHtmlSafe]'
})
export class HtmlSafeDirective {

    constructor(el: ElementRef) {
        /* Assumes element is an HTML input element */
        const pattern = /[`^_{}|<*>[\]/\\]/g;
        el.nativeElement.onkeypress = (event) => {
            if (event.key.match(pattern)) {
                event.stopPropagation();
                event.preventDefault();
            }
        };

        el.nativeElement.oninput = () => {
            const input: HTMLInputElement | HTMLTextAreaElement = el.nativeElement;
            input.value = input.value.replace(pattern, '');
        };
    }
}
