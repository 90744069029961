import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { LayoutService } from './services/layout.service';
import { HttpService } from './services/http.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit {
    title = 'farmlisting';

    isLoading = false;

    constructor(private layout: LayoutService, private httpService: HttpService, private changeDetector: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.httpService.getIsLoading().subscribe((isLoading) => {
            setTimeout(() => {
                this.isLoading = isLoading;
                this.changeDetector.detectChanges();
            });
        });
    }

    getClassName() {
        return `fl-main ${this.layout.getLayoutClass()}`;
    }
}
