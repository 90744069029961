import { Component, OnInit } from '@angular/core';
import { SessionService } from 'src/app/services/session.service';
import { Account } from '../../../model/account';
import { MatDialog } from '@angular/material/dialog';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { LayoutService } from 'src/app/services/layout.service';
import { ChangeEmailComponent } from '../change-email/change-email.component';
import { ChangeNameComponent } from '../change-name/change-name.component';
import { HttpService } from 'src/app/services/http.service';
import { IOrder, Order } from 'src/app/model/order';
import { SortType, OrderSortProperty, CONSTANTS } from 'src/app/model/enums';
import { Router } from '@angular/router';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.less']
})
export class SettingsComponent implements OnInit {

    labels = {
        lblSettings: 'Settings',
        lblUserInformation: 'User Information',
        lblOrderHistory: 'Order History',
        lblEditName: 'Edit name',
        lblChangeAccountEmail: 'Change account email',
        lblChangePassword: 'Change password',
        lblPassword: 'Password',

        colStatus: 'Status',
        colDelivery: 'Delivery',
        colPaymentDue: 'Payment due',
        colOrderDate: 'Order date',
        colAmount: 'Amount'
    };

    account: Account;
    orders: Order[];

    farmSlug: string;

    constructor(private session: SessionService, private dialog: MatDialog, private layout: LayoutService,
                private http: HttpService, private router: Router) { }

    ngOnInit() {
        this.account = this.session.getAccount();
        this.session.getFarm().subscribe((farm) => {
            if (farm) {
                this.farmSlug = farm.slug;
            }
        });
        this.session.getOffer().subscribe((offer) => {
            if (offer) {
                this.http.get<IOrder[]>('farmlisting/settings/orders/list', {
                    offersheetId: offer.id.toString(),
                    sortType: SortType.Descending,
                    sortProperty: OrderSortProperty.Created
                }).subscribe((orders: IOrder[]) => {
                    this.orders = orders.map((order: IOrder) => new Order(order));
                });
            }
        });
    }

    changeName() {
        this.dialog.open(ChangeNameComponent, this.layout.getModalConfig({ disableClose: false }));
    }

    changeEmail() {
        this.dialog.open(ChangeEmailComponent, this.layout.getModalConfig({ disableClose: false }));
    }

    changePassword() {
        this.dialog.open(ChangePasswordComponent, this.layout.getModalConfig({ disableClose: false }));
    }

    viewOrder(order: Order) {
        this.router.navigate([CONSTANTS.ROUTES.VIEW_ORDER(this.farmSlug, order.id)]);
    }

}
