import { Pipe, PipeTransform } from '@angular/core';
import { SessionService } from '../services/session.service';
import { Farm } from '../model/farm';
import { ImageResolutions, CONSTANTS } from '../model/enums';

@Pipe({
    name: 'farmImage'
})
export class FarmImagePipe implements PipeTransform {

    farmId: number;

    constructor(private session: SessionService) {
        this.session.getFarm().subscribe((farm: Farm) => {
            if (farm) {
                this.farmId = farm.id;
            }
        });
    }

    transform(imageUrl: string, resolution?: ImageResolutions): string {
        if (resolution) {
            const resolutionString = resolution.toString() + 'x' + resolution.toString();
            return CONSTANTS.API_ROOT + CONSTANTS.FARM_IMG_URL + this.farmId
                + '/thumbnails/' + imageUrl.replace('.jpg', '_' + resolutionString + '.jpg');
        } else {
            return imageUrl;
        }
    }

}
